import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IFurnaceRepairPage } from "src/@types/hvacServices";

export const FURNACE_REPAIR_SLICE_NAME = "furnaceRepair";

export const getFurnaceRepairPageAsync = createAsyncThunk(
  `${FURNACE_REPAIR_SLICE_NAME}/fetchFurnaceRepair`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IFurnaceRepairPage>(
        "/pages/furnace-repair/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
