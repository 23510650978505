import { createSlice } from "@reduxjs/toolkit";
import { IAppliance } from "src/@types/appliance";
import { APPLIANCE } from "./actions";
import { getApplianceInstallationPageReducer } from "./reducers";

export interface ApplianceInstallationState {
  isLoading: boolean;
  data: IAppliance;
}

const initialState: ApplianceInstallationState = {
  isLoading: false,
  data: null,
};

export const { reducer: applianceInstallation } = createSlice({
  name: APPLIANCE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getApplianceInstallationPageReducer(builder);
  },
});
