import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IMembershipPage } from "src/@types/membership";
import { getMembershipPageAsync } from "./actions";
import { MembershipState } from "./slice";

type ActionReducerMapBuilderWithMembershipState =
  ActionReducerMapBuilder<MembershipState>;

export const getMembershipPageReducer = (
  builder: ActionReducerMapBuilderWithMembershipState
) => {
  builder.addCase(getMembershipPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getMembershipPageAsync.fulfilled,
    (state, action: PayloadAction<IMembershipPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getMembershipPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
