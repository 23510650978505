import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IDetailsData } from "src/@types/details";

export const DETAILS_SLICE_NAME = "details";

export const getDetailsAsync = createAsyncThunk(
  `${DETAILS_SLICE_NAME}/fetchDetails`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IDetailsData>(`/details/`);

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
