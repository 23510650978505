import { FC, ReactNode, useRef } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import { useClickOutside } from "src/hooks/useClickOutside";
import { MODAL_ROOT_ELEMENT } from "src/constants/rootElements";
import { CloseButton } from "./CloseButton";

interface Props {
  children?: ReactNode;
  isOpen: boolean;
  isActivePortal?: boolean;
  isShownOverlay?: boolean;
  isShownCloseButton?: boolean;
  isHasOutsideCloseButton?: boolean;
  isActiveDefaultHoverCloseButton?: boolean;
  isActiveCloseClickOutside?: boolean;
  overlayClassName?: string;
  className?: string;
  closeButtonClassName?: string;
  onClose: VoidFunction;
}

export const ModalWindow: FC<Props> = ({
  children,
  overlayClassName,
  className,
  closeButtonClassName,
  isOpen,
  isActivePortal,
  isShownOverlay = true,
  isShownCloseButton = true,
  isHasOutsideCloseButton = false,
  isActiveDefaultHoverCloseButton,
  isActiveCloseClickOutside = true,
  onClose,
}) => {
  const modalRef = useRef(null);

  const isActiveClickOutside = isOpen && isActiveCloseClickOutside;
  useClickOutside(modalRef, onClose, isActiveClickOutside);

  const combinedOverlayClassNames = cn(
    "invisible fixed top-0 left-0 w-full h-full bg-gray-light opacity-0 z-30 transition-opacity duration-300",
    overlayClassName,
    {
      "!visible !opacity-20": isOpen,
    }
  );

  const combinedModalClassNames = cn(
    "invisible fixed top-0 right-1/2 translate-x-1/2 translate-y-full overflow-hidden opacity-0 rounded-lg transition-all duration-300 z-40",
    className,
    {
      "!visible opacity-100 ": isOpen,
    }
  );

  const component = (
    <>
      {isShownOverlay && <div className={combinedOverlayClassNames}></div>}
      <div ref={modalRef} className={combinedModalClassNames}>
        {isShownCloseButton && !isHasOutsideCloseButton && (
          <div className="flex justify-end w-full">
            <CloseButton className={closeButtonClassName} onClose={onClose} />
          </div>
        )}
        {children}
      </div>
      {isShownCloseButton && isHasOutsideCloseButton && (
        <CloseButton
          className={closeButtonClassName}
          isActiveDefaultHoverEffect={isActiveDefaultHoverCloseButton}
          onClose={onClose}
        />
      )}
    </>
  );

  return isActivePortal
    ? createPortal(component, MODAL_ROOT_ELEMENT)
    : component;
};
