import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IServiceAreaResponse } from "src/@types/serviceArea";

export const SERVICE_AREA = "serviceArea";

export const getServiceAreaAsync = createAsyncThunk(
  `${SERVICE_AREA}/fetchServiceArea`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IServiceAreaResponse>(
        "/pages/service-area/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
