import React, { useState } from "react";
import { HEADER_NAV } from "src/components/Header/constants";
import { MobNavMenuListItem } from "./MobNavMenuListItem";

export const MobNavMenuList = () => {
  const [openedService, setOpenedService] = useState<number | string>(null);

  return (
    <nav>
      <ul className="flex flex-col text-black-base">
        {HEADER_NAV.map((item) => (
          <MobNavMenuListItem
            key={item.id}
            openedService={openedService}
            setOpenedService={setOpenedService}
            {...item}
          />
        ))}
      </ul>
    </nav>
  );
};
