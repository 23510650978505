import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorGetInTouchState = (state: RootState) => state.getInTouch;

export const selectorGetInTouch = createSelector(
  selectorGetInTouchState,
  (GetInTouchState) => GetInTouchState.data
);

export const selectorGetInTouchIsLoading = createSelector(
  selectorGetInTouchState,
  (GetInTouchState) => GetInTouchState.isLoading
);
