import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorHomeState = (state: RootState) =>
  state.home;

export const selectorHome = createSelector(
  selectorHomeState,
  (homeState) => homeState.data
);

export const selectorHomesIsLoading = createSelector(
  selectorHomeState,
  (homeState) => homeState.isLoading
);
