import { createSlice } from "@reduxjs/toolkit";
import { IBreakerInstallationPage } from "src/@types/electricalServices";
import { BREAKER_INSTALLATION_SLICE_NAME } from "./actions";
import { getBreakerInstallationPageReducer } from "./reducers";

export interface BreakerInstallationState {
  isLoading: boolean;
  data: IBreakerInstallationPage;
}

const initialState: BreakerInstallationState = {
  isLoading: false,
  data: null,
};

export const { reducer: breakerInstallation } = createSlice({
  name: BREAKER_INSTALLATION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getBreakerInstallationPageReducer(builder);
  },
});
