export const PATHNAMES = {
  HOME: "/",
  HIDDEN_HOME: "/ads",

  AC_REPAIR: "/ac-repair",
  FURNACE_REPAIR: "/furnace-repair",
  HVAC_MAINTENANCE: "/hvac-maintenance",
  AC_INSTALLATION: "/ac-installation",
  FURNACE_INSTALLATION: "/furnace-installation",
  HEAT_PUMP_INSTALLATION: "/heat-pump-installation",

  ELECTRICAL_REPAIRS: "/electrical-repairs",
  ROUGH_WIRING: "/rough-wiring",
  ELECTRICAL_BREAKER_INSTALLATION: "/electrical-breaker-installation",
  ELECTRICAL_PANEL_INSTALLATION: "/electrical-panel-installation",
  RESIDENTIAL_ELECTRICAL_SERVICES: "/residential-electrical-services",
  EV_CHARGER_INSTALLATION: "/ev-charger-installation",

  MAJOR_APPLIANCE_REPAIR: "/major-appliance-repair",
  APPLIANCE_MAINTENANCE: "/appliance-maintenance",
  APPLIANCE_INSTALLATION: "/appliance-installation",

  NEWS_TIPS: "/news-tips",
  NEWS_TIPS_POST: "/news-tips/:slug",
  CAREER: "/career",
  CAREER_POST: "/career/:slug",
  MEMBERSHIP: "/membership",
  SERVICE_AREA: "/service-area",
  SERVICE_AREA_REGION: "/service-area/:slug",

  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",

  NOT_FOUND: "/*",
};
