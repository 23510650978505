import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IPanelInstallationPage } from "src/@types/electricalServices";

export const PANEL_INSTALLATION_SLICE_NAME = "panelInstallation";

export const getPanelInstallationPageAsync = createAsyncThunk(
  `${PANEL_INSTALLATION_SLICE_NAME}/fetchPanelInstallation`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IPanelInstallationPage>(
        `/pages/electrical-panel-installation/`
      ); 

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
