import { createSlice } from "@reduxjs/toolkit";
import { INewsTipsBookNow } from "src/@types/postFormsData";
import { postNewsTipsBookNowReducer } from "./reducers";
import { NEWS_TIPS_BOOK_NOW } from "./actions";

export interface newsTipsBookNowState {
  isLoading: boolean;
  data: INewsTipsBookNow;
}

const initialState: newsTipsBookNowState = {
  isLoading: false,
  data: null,
};

export const { reducer: newsTipsBookNow } = createSlice({
  name: NEWS_TIPS_BOOK_NOW,
  initialState,
  reducers: {},
  extraReducers(builder) {
    postNewsTipsBookNowReducer(builder);
  },
});
