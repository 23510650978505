import { createAsyncThunk } from "@reduxjs/toolkit";
import { IApplyBelow } from "src/@types/postFormsData";
import { NotificationService } from "src/helpers/notifications";
import { instance } from "src/services/api-client";

export const APPLY_BELOW = "applyBelow";

export const postApplyBelow = createAsyncThunk(
  `${APPLY_BELOW}/postApplyBelow`,
  async (applyBelowData: IApplyBelow, { rejectWithValue }) => {
    try {
      const { data } = await instance.post<IApplyBelow>(
        `/career-posts/applybellow/`,
        applyBelowData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return data;
    } catch ({ response }) {
      console.log(response);

      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
