import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IPanelInstallationPage } from "src/@types/electricalServices";
import { getPanelInstallationPageAsync } from "./actions";
import { PanelInstallationState } from "./slice";

type ActionReducerMapBuilderWithPanelInstallationState =
  ActionReducerMapBuilder<PanelInstallationState>;

export const getPanelInstallationPageReducer = (
  builder: ActionReducerMapBuilderWithPanelInstallationState
) => {
  builder.addCase(getPanelInstallationPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getPanelInstallationPageAsync.fulfilled,
    (state, action: PayloadAction<IPanelInstallationPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getPanelInstallationPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
