import { PATHNAMES } from "src/constants/routes";
import { INavMenu } from "./types";

export const HEADER_NAV: INavMenu[] = [
  {
    id: 1,
    label: "HVAC",
    path: PATHNAMES.AC_REPAIR,
    services: [
      {
        id: crypto.randomUUID(),
        label: "AC Repair",
        path: PATHNAMES.AC_REPAIR,
      },
      {
        id: crypto.randomUUID(),
        label: "Furnace Repair",
        path: PATHNAMES.FURNACE_REPAIR,
      },
      {
        id: crypto.randomUUID(),
        label: "HVAC Maintenance",
        path: PATHNAMES.HVAC_MAINTENANCE,
      },
      {
        id: crypto.randomUUID(),
        label: "AC Installation",
        path: PATHNAMES.AC_INSTALLATION,
      },
      {
        id: crypto.randomUUID(),
        label: "Furnace Installation",
        path: PATHNAMES.FURNACE_INSTALLATION,
      },
      {
        id: crypto.randomUUID(),
        label: "Heat Pump Installation",
        path: PATHNAMES.HEAT_PUMP_INSTALLATION,
      },
    ],
  },
  {
    id: 2,
    label: "ELECTRICAL",
    path: PATHNAMES.ELECTRICAL_REPAIRS,
    services: [
      {
        id: crypto.randomUUID(),
        label: "Electrical Repairs",
        path: PATHNAMES.ELECTRICAL_REPAIRS,
      },
      {
        id: crypto.randomUUID(),
        label: "Rough Wiring",
        path: PATHNAMES.ROUGH_WIRING,
      },
      {
        id: crypto.randomUUID(),
        label: "Electrical Breaker Installation",
        path: PATHNAMES.ELECTRICAL_BREAKER_INSTALLATION,
      },
      {
        id: crypto.randomUUID(),
        label: "Electrical Panel Installation",
        path: PATHNAMES.ELECTRICAL_PANEL_INSTALLATION,
      },
      {
        id: crypto.randomUUID(),
        label: "Residential Electrical Services",
        path: PATHNAMES.RESIDENTIAL_ELECTRICAL_SERVICES,
      },
      {
        id: crypto.randomUUID(),
        label: "EV Charger Installation",
        path: PATHNAMES.EV_CHARGER_INSTALLATION,
      },
    ],
  },

  {
    id: 3,
    label: "APPLIANCE",
    path: PATHNAMES.MAJOR_APPLIANCE_REPAIR,
    services: [
      {
        id: crypto.randomUUID(),
        label: "Major Appliance Repair",
        path: PATHNAMES.MAJOR_APPLIANCE_REPAIR,
      },
      {
        id: crypto.randomUUID(),
        label: "Appliance Maintenance",
        path: PATHNAMES.APPLIANCE_MAINTENANCE,
      },
      {
        id: crypto.randomUUID(),
        label: "Appliance Installation",
        path: PATHNAMES.APPLIANCE_INSTALLATION,
      },
    ],
  },

  {
    id: 4,
    label: "ABOUT US",
    path: PATHNAMES.NEWS_TIPS,
    services: [
      {
        id: crypto.randomUUID(),
        label: "News and Tips",
        path: PATHNAMES.NEWS_TIPS,
      },
      {
        id: crypto.randomUUID(),
        label: "Career",
        path: PATHNAMES.CAREER,
      },
      {
        id: crypto.randomUUID(),
        label: "Membership",
        path: PATHNAMES.MEMBERSHIP,
      },
    ],
  },

  {
    id: 5,
    label: "SERVICE AREA",
    path: PATHNAMES.SERVICE_AREA,
  },
];
