import React, { FC } from "react";
import cn from "classnames";
import { DropdownMenuListItem } from "./DropdownMenuListItem";
import { INavLink } from "../Header/types";

interface Props {
  className?: string;
  items?: INavLink[];
}

export const DropdownMenuList: FC<Props> = ({ className, items = [] }) => {
  if (!items.length) return null;

  return (
    <ul className={cn("w-full h-full py-2", className)}>
      {items.map((item, index) => (
        <DropdownMenuListItem key={index} {...item} />
      ))}
    </ul>
  );
};
