import { createSlice } from "@reduxjs/toolkit";
import { IEvChargerInstallationPage } from "src/@types/electricalServices";
import { EV_CHARGER_INSTALLATION_SLICE_NAME } from "./actions";
import { getEvChargerInstallationPageReducer } from "./reducers";

export interface EvChargerInstallationState {
  isLoading: boolean;
  data: IEvChargerInstallationPage;
}

const initialState: EvChargerInstallationState = {
  isLoading: false,
  data: null,
};

export const { reducer: evChargerInstallation } = createSlice({
  name: EV_CHARGER_INSTALLATION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getEvChargerInstallationPageReducer(builder);
  },
});
