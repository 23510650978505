import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { ICouponsResponse } from "src/@types/coupon";

export const COUPONS_SLICE_NAME = "coupons";

export const getCouponsAsync = createAsyncThunk(
  `${COUPONS_SLICE_NAME}/fetchCoupons`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<ICouponsResponse[]>("/coupons/");

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
