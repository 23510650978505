import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { ICareerItem } from "src/@types/career";

export const CURRENT_CAREER_POST_SLICE_NAME = "currentCareerPost";

export const getCurrentCareerPostAsync = createAsyncThunk(
  `${CURRENT_CAREER_POST_SLICE_NAME}/fetchCurrentCareerPost`,
  async (slug: string, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<ICareerItem>(
        `/career-posts/${slug}/`
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
