import React, { FC } from "react";
import cn from "classnames";
import { DropdownMenuList } from "./DropdownMenuList";
import { INavLink } from "../Header/types"; 

interface Props {
  isOpen?: boolean;
  items?: INavLink[];
  className?: string;
}

export const DropdownMenu: FC<Props> = ({ items, isOpen, className }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <div
      className={cn(
        "absolute top-full left-0 opacity-0 pointer-events-none bg-black-base transition-all duration-300 z-50 border border-yellow-base rounded-lg transform",
        {
          "translate-y-0 !opacity-100 pointer-events-auto": isOpen,
        },
        className
      )}
    >
      <div className="w-80">
        <DropdownMenuList items={items} />
      </div>
    </div>
  );
};
