import { createSlice } from "@reduxjs/toolkit";
import { IDetailsData } from "src/@types/details";
import { DETAILS_SLICE_NAME } from "./actions";
import { getDetailsReducer } from "./reducers";

export interface DetailsState {
  isLoading: boolean;
  data: IDetailsData;
}

const initialState: DetailsState = {
  isLoading: false,
  data: null,
};

export const { reducer: details } = createSlice({
  name: DETAILS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getDetailsReducer(builder);
  },
});
