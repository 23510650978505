import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IResponseWithPagination } from "src/@types/api";
import { ICareerItem } from "src/@types/career";
import { CareerState } from "./slice";
import { getCareerAsync } from "./actions";

type ActionReducerMapBuilderWithCareerState =
  ActionReducerMapBuilder<CareerState>;

export const getCareerReducer = (
  builder: ActionReducerMapBuilderWithCareerState
) => {
  builder.addCase(getCareerAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getCareerAsync.fulfilled,
    (state, action: PayloadAction<IResponseWithPagination<ICareerItem>>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getCareerAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
