import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IBreakerInstallationPage } from "src/@types/electricalServices";
import { getBreakerInstallationPageAsync } from "./actions";
import { BreakerInstallationState } from "./slice";

type ActionReducerMapBuilderWithBreakerInstallationState =
  ActionReducerMapBuilder<BreakerInstallationState>;

export const getBreakerInstallationPageReducer = (
  builder: ActionReducerMapBuilderWithBreakerInstallationState
) => {
  builder.addCase(getBreakerInstallationPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getBreakerInstallationPageAsync.fulfilled,
    (state, action: PayloadAction<IBreakerInstallationPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getBreakerInstallationPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
