import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRoughWiringPage } from "src/@types/electricalServices";
import { NotificationService } from "src/helpers/notifications";
import { instance } from "src/services/api-client";

export const ROUGH_WIRING_SLICE_NAME = "roughWiring";

export const getRoughWiringPageAsync = createAsyncThunk(
  `${ROUGH_WIRING_SLICE_NAME}/fetchRoughWiring`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IRoughWiringPage>(
        "/pages/rough-wiring/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
