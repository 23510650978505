import { createAsyncThunk } from "@reduxjs/toolkit";
import { IGetInTouch } from "src/@types/postFormsData";
import { NotificationService } from "src/helpers/notifications";
import { instance } from "src/services/api-client";

export const GET_IN_TOUCH = "getInTouch";

export const postGetInTouch = createAsyncThunk(
  `${GET_IN_TOUCH}/postGetInTouchForm`,
  async (getInTouch: IGetInTouch, { rejectWithValue }) => {
    try {
      const { data } = await instance.post<IGetInTouch>(
        `/get-in-touch/`,
        getInTouch
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
