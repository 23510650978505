import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IHomePage } from "src/@types/home";

export const HOME = "home";

export const getHomePageAsync = createAsyncThunk(
  `${HOME}/fetchHome`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IHomePage>(`/pages/home/`);

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
