import { createSlice } from "@reduxjs/toolkit";
import { INewsTipsItem } from "src/@types/newsTips";
import { CURRENT_NEWS_TIPS_POST_SLICE_NAME } from "./actions";
import { getCurrentNewsTipsPostReducer } from "./reducers";

export interface CurrentNewsTipsPostState {
  isLoading: boolean;
  post: INewsTipsItem;
}

const initialState: CurrentNewsTipsPostState = {
  isLoading: false,
  post: null,
};

export const { reducer: currentNewsTipsPost } = createSlice({
  name: CURRENT_NEWS_TIPS_POST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getCurrentNewsTipsPostReducer(builder);
  },
});
