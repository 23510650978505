import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IFurnaceInstallationPage } from "src/@types/hvacServices";

export const FURNACE_INSTALLATION_SLICE_NAME = "furnaceInstallation";

export const getFurnaceInstallationPageAsync = createAsyncThunk(
  `${FURNACE_INSTALLATION_SLICE_NAME}/fetchFurnaceInstallation`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IFurnaceInstallationPage>(
        "/pages/furnace-installation/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
