import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorDetailsState = (state: RootState) => state.details;

export const selectorDetails = createSelector(
  selectorDetailsState,
  (detailsState) => detailsState.data
);

export const selectorDetailsIsLoading = createSelector(
  selectorDetailsState,
  (detailsState) => detailsState.isLoading
);
