import { createSlice } from "@reduxjs/toolkit";
import { IMaintenancePage } from "src/@types/hvacServices";
import { MAINTENANCE_SLICE_NAME } from "./actions";
import { getMaintenancePageReducer } from "./reducers";

export interface MaintenanceState {
  isLoading: boolean;
  data: IMaintenancePage;
}

const initialState: MaintenanceState = {
  isLoading: false,
  data: null,
};

export const { reducer: maintenance } = createSlice({
  name: MAINTENANCE_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getMaintenancePageReducer(builder);
  },
});
