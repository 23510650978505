import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IAppliance } from "src/@types/appliance";
import { APPLIANCE } from "../installation/actions";

export const getApplianceMaintenancePageAsync = createAsyncThunk(
  `${APPLIANCE}/fetchMaintenance`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IAppliance>(
        `/pages/appliance/maintenance/`
      ); 
      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
