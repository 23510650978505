import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { INewsTipsItem } from "src/@types/newsTips";

export const CURRENT_NEWS_TIPS_POST_SLICE_NAME = "currentNewsTipsPost";

export const getCurrentNewsTipPostAsync = createAsyncThunk(
  `${CURRENT_NEWS_TIPS_POST_SLICE_NAME}/fetchCurrentNewsTipPost`,
  async (slug: string, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<INewsTipsItem>(`/news/${slug}/`);

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
