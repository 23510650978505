import { useRef } from "react";
import ReactSelect from "react-select";
import { useController } from "react-hook-form";
import { customStyles } from "./customStyle";

const CustomSelect = ({
  name,
  control,
  options,
  placeholder,
  openWithCoupon = false,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  const selectRef = useRef(null);

  const handleChange = (selectedOption) => {
    // Якщо openWithCoupon true, то передаємо тільки coupon_slug, інакше передаємо label
    const valueToPass = openWithCoupon
      ? selectedOption?.coupon_slug
      : selectedOption?.label;
    field.onChange(valueToPass);

    // Знімаємо фокус з Select після вибору опції
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  return (
    <div className="relative">
      <ReactSelect
        ref={selectRef}
        {...field}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) =>
          openWithCoupon ? option.coupon_slug : option.label
        } // Використовуємо coupon_slug або label як значення
        value={
          openWithCoupon
            ? options.find((option) => option.coupon_slug === field.value) // Знаходимо опцію по coupon_slug
            : options.find((option) => option.label === field.value) // Знаходимо опцію по label
        }
      />
      {error && (
        <p className="absolute -bottom-6 left-4 text-13 text-red-600">
          Required field
        </p>
      )}
    </div>
  );
};

export default CustomSelect;
