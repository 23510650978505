import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IAcInstallationPage } from "src/@types/hvacServices";
import { getAcInstallationPageAsync } from "./actions";
import { AcInstallationState } from "./slice";

type ActionReducerMapBuilderWithAcInstallationState =
  ActionReducerMapBuilder<AcInstallationState>;

export const getAcInstallationPageReducer = (
  builder: ActionReducerMapBuilderWithAcInstallationState
) => {
  builder.addCase(getAcInstallationPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getAcInstallationPageAsync.fulfilled,
    (state, action: PayloadAction<IAcInstallationPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getAcInstallationPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
