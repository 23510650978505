import { createSlice } from "@reduxjs/toolkit";
import { IRoughWiringPage } from "src/@types/electricalServices";
import { ROUGH_WIRING_SLICE_NAME } from "./actions";
import { getRoughWiringPageReducer } from "./reducers";

export interface RoughWiringState {
  isLoading: boolean;
  data: IRoughWiringPage;
}

const initialState: RoughWiringState = {
  isLoading: false,
  data: null,
};

export const { reducer: roughWiring } = createSlice({
  name: ROUGH_WIRING_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getRoughWiringPageReducer(builder);
  },
});
