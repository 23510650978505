import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IPostFormsData } from "src/@types/postFormsData";
import { postMembershipState } from "./slice";
import { postMembership } from "./actions";

type ActionReducerMapBuilderWithMembershipState =
  ActionReducerMapBuilder<postMembershipState>;

export const postMembershipReducer = (
  builder: ActionReducerMapBuilderWithMembershipState
) => {
  builder.addCase(postMembership.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    postMembership.fulfilled,
    (state, action: PayloadAction<IPostFormsData>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(postMembership.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
