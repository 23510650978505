import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { INewsTipsBookNow } from "src/@types/postFormsData";
import { newsTipsBookNowState } from "./slice";
import { postNewsTipsBookNow } from "./actions";

type ActionReducerMapBuilderWithNewsTipsBookNowState =
  ActionReducerMapBuilder<newsTipsBookNowState>;

export const postNewsTipsBookNowReducer = (
  builder: ActionReducerMapBuilderWithNewsTipsBookNowState
) => {
  builder.addCase(postNewsTipsBookNow.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    postNewsTipsBookNow.fulfilled,
    (state, action: PayloadAction<INewsTipsBookNow>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(postNewsTipsBookNow.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
