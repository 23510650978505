import { createSlice } from "@reduxjs/toolkit";
import { IPanelInstallationPage } from "src/@types/electricalServices";
import { PANEL_INSTALLATION_SLICE_NAME } from "./actions";
import { getPanelInstallationPageReducer } from "./reducers";

export interface PanelInstallationState {
  isLoading: boolean;
  data: IPanelInstallationPage;
}

const initialState: PanelInstallationState = {
  isLoading: false,
  data: null,
};

export const { reducer: panelInstallation } = createSlice({
  name: PANEL_INSTALLATION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getPanelInstallationPageReducer(builder);
  },
});
