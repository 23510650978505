import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IHeatPumpInstallationPage } from "src/@types/hvacServices";
import { getHeatPumpInstallationPageAsync } from "./actions";
import { HeatPumpInstallationState } from "./slice";

type ActionReducerMapBuilderWithHeatPumpInstallationState =
  ActionReducerMapBuilder<HeatPumpInstallationState>;

export const getHeatPumpInstallationPageReducer = (
  builder: ActionReducerMapBuilderWithHeatPumpInstallationState
) => {
  builder.addCase(getHeatPumpInstallationPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getHeatPumpInstallationPageAsync.fulfilled,
    (state, action: PayloadAction<IHeatPumpInstallationPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getHeatPumpInstallationPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
