import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IGetInTouch } from "src/@types/postFormsData";
import { getInTouchState } from "./slice";
import { postGetInTouch } from "./actions";

type ActionReducerMapBuilderWithGetIntouchState =
  ActionReducerMapBuilder<getInTouchState>;

export const postgetInTouchReducer = (
  builder: ActionReducerMapBuilderWithGetIntouchState
) => {
  builder.addCase(postGetInTouch.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    postGetInTouch.fulfilled,
    (state, action: PayloadAction<IGetInTouch>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(postGetInTouch.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
