import { createSlice } from "@reduxjs/toolkit";
import { IServiceAreaRegionPage } from "src/@types/serviceArea";
import { SERVICE_AREA_REGION_SLICE_NAME } from "./actions";
import { getServiceAreaRegionReducer } from "./reducers";

export interface ServiceAreaRegionState {
  isLoading: boolean;
  data: IServiceAreaRegionPage;
}

const initialState: ServiceAreaRegionState = {
  isLoading: false,
  data: null,
};

export const { reducer: serviceAreaRegion } = createSlice({
  name: SERVICE_AREA_REGION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getServiceAreaRegionReducer(builder);
  },
});
