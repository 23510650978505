export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#E1B81F",
    color: "#121212",
    padding: "6px 12px",
    borderRadius: state.isFocused ? "10px 10px 0 0" : "10px", // Змінюємо border-radius при фокусі
    fontSize: "20px",
    fontWeight: "500",
    borderColor: state.isFocused ? "#121212" : "#121212", // Чорний outline при фокусі
    boxShadow: state.isFocused ? "0 0 0 1px #121212" : "none", // Додаємо чорний outline при фокусі
    cursor: "pointer",

    "&:hover": {
      borderColor: "#121212",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    width: "24px",
    height: "24px",
    color: state.isFocused ? "#121212" : "#121212",
    padding: 0,
    transform: state.isFocused ? "rotate(180deg)" : "rotate(0deg)", 
    transition: "transform 0.2s ease",
    "&:hover": {
      cursor: "pointer",
    },
  }),
  // Стилізація SVG, яка є іконкою стрілки
  dropdownIndicatorContainer: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: {
      width: "24px",
      height: "24px",
    },
  }),
  indicatorSeparator: () => ({
    display: "none", // Видалення сепаратора
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #E1B81F",
    padding: "8px 12px",
    backgroundColor: "#121212",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#C39F16",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#121212",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#121212",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    marginTop: 0, // Прибираємо відступ зверху меню
    marginBottom: 0, // Прибираємо відступ знизу меню
    borderRadius: "0 0 10px 10px",
    border: "1px solid #E1B81F",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0, // Прибираємо падінг зверху списку опцій
    paddingBottom: 0, // Прибираємо падінг знизу списку опцій
    borderRadius: "0 0 10px 10px",
  }),
};
