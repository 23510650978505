import { FC, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import loadable from "@loadable/component";
import { useAppDispatch } from "src/hooks/redux";
import { getDetailsAsync } from "src/redux/details/actions";
import { PATHNAMES } from "src/constants/routes";
import HiddenHome from "src/pages/HiddenHome";

// Dynamically import each route component
const Home = loadable(() => import("src/pages/Home"));
const ACRepair = loadable(() => import("src/pages/Services/HVAC/AСRepair"));
const NewsTips = loadable(() => import("src/pages/NewsTips"));
const NewsTipsPost = loadable(() => import("src/pages/NewsTips/Post"));
const Career = loadable(() => import("src/pages/Career"));
const CareerPost = loadable(() => import("src/pages/Career/Post"));
const Membership = loadable(() => import("src/pages/Membership"));
const NotFound = loadable(() => import("src/pages/NotFound"));
const ServiceArea = loadable(() => import("src/pages/ServiceArea"));
const ElectricalRepairs = loadable(
  () => import("src/pages/Services/Electrical/ElectricalRepairs")
);
const ResidentialServices = loadable(
  () => import("src/pages/Services/Electrical/ResidentialServices")
);
const HVACMaintenance = loadable(
  () => import("src/pages/Services/HVAC/Maintenance")
);
const MajorRepair = loadable(
  () => import("src/pages/Services/Appliance/MajorRepair")
);
const Maintenance = loadable(
  () => import("src/pages/Services/Appliance/Maintenance")
);
const RoughWiring = loadable(
  () => import("src/pages/Services/Electrical/RoughWiring")
);
const ApplianceInstallation = loadable(
  () => import("src/pages/Services/Appliance/Installation")
);
const BreakerInstallation = loadable(
  () => import("src/pages/Services/Electrical/BreakerInstallation")
);
const FurnaceInstallation = loadable(
  () => import("src/pages/Services/HVAC/FurnanceInstallation")
);
const FurnaceRepair = loadable(
  () => import("src/pages/Services/HVAC/FurnaceRepair")
);
const ACInstallation = loadable(
  () => import("src/pages/Services/HVAC/AСInstallation")
);
const EVChargerInstallation = loadable(
  () => import("src/pages/Services/Electrical/EVChargerinstallation")
);
const PanelInstallation = loadable(
  () => import("src/pages/Services/Electrical/PanelInstallation")
);
const HeatPumpInstallation = loadable(
  () => import("src/pages/Services/HVAC/HeatPumpInstallation")
);
const ServiceAreaRegion = loadable(
  () => import("src/pages/ServiceArea/Region")
);
const PrivacyPolicy = loadable(() => import("src/pages/PrivacyPolicy"));
const TermsOfUse = loadable(() => import("src/pages/TermsOfUse"));

// Define routes
const ROUTES = [
  { element: <Home />, path: PATHNAMES.HOME },
  { element: <HiddenHome />, path: PATHNAMES.HIDDEN_HOME },
  { element: <ElectricalRepairs />, path: PATHNAMES.ELECTRICAL_REPAIRS },
  {
    element: <BreakerInstallation />,
    path: PATHNAMES.ELECTRICAL_BREAKER_INSTALLATION,
  },
  {
    element: <PanelInstallation />,
    path: PATHNAMES.ELECTRICAL_PANEL_INSTALLATION,
  },
  { element: <RoughWiring />, path: PATHNAMES.ROUGH_WIRING },
  {
    element: <ResidentialServices />,
    path: PATHNAMES.RESIDENTIAL_ELECTRICAL_SERVICES,
  },
  {
    element: <EVChargerInstallation />,
    path: PATHNAMES.EV_CHARGER_INSTALLATION,
  },
  { element: <NewsTips />, path: PATHNAMES.NEWS_TIPS },
  { element: <NewsTipsPost />, path: PATHNAMES.NEWS_TIPS_POST },
  { element: <Career />, path: PATHNAMES.CAREER },
  { element: <CareerPost />, path: PATHNAMES.CAREER_POST },
  { element: <Membership />, path: PATHNAMES.MEMBERSHIP },
  { element: <ServiceArea />, path: PATHNAMES.SERVICE_AREA },
  { element: <ServiceAreaRegion />, path: PATHNAMES.SERVICE_AREA_REGION },
  { element: <ACRepair />, path: PATHNAMES.AC_REPAIR },
  { element: <HVACMaintenance />, path: PATHNAMES.HVAC_MAINTENANCE },
  { element: <ACInstallation />, path: PATHNAMES.AC_INSTALLATION },
  { element: <FurnaceRepair />, path: PATHNAMES.FURNACE_REPAIR },
  { element: <FurnaceInstallation />, path: PATHNAMES.FURNACE_INSTALLATION },
  { element: <HeatPumpInstallation />, path: PATHNAMES.HEAT_PUMP_INSTALLATION },
  { element: <MajorRepair />, path: PATHNAMES.MAJOR_APPLIANCE_REPAIR },
  { element: <Maintenance />, path: PATHNAMES.APPLIANCE_MAINTENANCE },
  {
    element: <ApplianceInstallation />,
    path: PATHNAMES.APPLIANCE_INSTALLATION,
  },
  { element: <PrivacyPolicy />, path: PATHNAMES.PRIVACY_POLICY },
  { element: <TermsOfUse />, path: PATHNAMES.TERMS_OF_USE },
  { element: <NotFound />, path: PATHNAMES.NOT_FOUND },
];

const AppRoutes: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDetailsAsync());
  }, [dispatch]);

  return useRoutes(ROUTES);
};

export default AppRoutes;
