import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { INewsTipsItem } from "src/@types/newsTips";
import { IResponseWithPagination } from "src/@types/api";

export const NEWSTIPS_SLICE_NAME = "newsTips";

export const getNewsTipsAsync = createAsyncThunk(
  `${NEWSTIPS_SLICE_NAME}/fetchNewsTips`,
  async (page: number = 1, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<
        IResponseWithPagination<INewsTipsItem>
      >(`/news/?page=${page}`);

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
