import React, { FC } from "react";
import { useModal } from "src/hooks/useModal";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";
import { ReactComponent as PhoneIcon } from "src/assets/icons/phone-black-base.svg";
import { ReactComponent as BookIcon } from "src/assets/icons/book-black-base.svg";
import { ReactComponent as BurgerMenuIcon } from "src/assets/icons/burger-menu.svg";
import { Link } from "../Link";
import { BookNowModal } from "../BookNowModal";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";

const ICON_SIZE = 20;

interface Props {
  hiddenPage?: boolean;
  setIsOpenDropdownMenu: (
    isOpen: boolean | ((isOpen: boolean) => boolean)
  ) => void;
}

export const ContactButtons: FC<Props> = ({
  setIsOpenDropdownMenu,
  hiddenPage = false,
}) => {
  const bookNow = useModal();
  const contactData = useAppSelector(selectorDetails);

  const toggleIsOpen = () => setIsOpenDropdownMenu((prev) => !prev);

  return (
    <>
      <div className="flex items-center gap-5">
        <Link
          className="group"
          href={
            hiddenPage
              ? contactData?.CONTACTS[3]?.href
              : contactData?.CONTACTS[1]?.href
          }
        >
          <Button
            className="w-full"
            type="button"
            variant={ButtonVariants.YELLOW_FILLED_ROUNDED}
          >
            <PhoneIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              className="hover:scale-125"
            />
          </Button>
        </Link>

        <Button
          type="button"
          variant={ButtonVariants.YELLOW_FILLED_ROUNDED}
          onClick={bookNow.openModal}
        >
          <BookIcon
            width={ICON_SIZE}
            height={ICON_SIZE}
            className="hover:scale-125"
          />
        </Button>

        <Button
          type="button"
          variant={ButtonVariants.YELLOW_FILLED_ROUNDED}
          onClick={toggleIsOpen}
        >
          <BurgerMenuIcon
            width={ICON_SIZE}
            height={ICON_SIZE}
            className="hover:scale-125"
          />
        </Button>
      </div>

      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
    </>
  );
};
