import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IFurnaceInstallationPage } from "src/@types/hvacServices";
import { getFurnaceInstallationPageAsync } from "./actions";
import { FurnaceInstallationState } from "./slice";

type ActionReducerMapBuilderWithFurnaceInstallationState =
  ActionReducerMapBuilder<FurnaceInstallationState>;

export const getFurnaceInstallationPageReducer = (
  builder: ActionReducerMapBuilderWithFurnaceInstallationState
) => {
  builder.addCase(getFurnaceInstallationPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getFurnaceInstallationPageAsync.fulfilled,
    (state, action: PayloadAction<IFurnaceInstallationPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getFurnaceInstallationPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
