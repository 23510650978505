import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IDetailsData } from "src/@types/details";
import { getDetailsAsync } from "./actions";
import { DetailsState } from "./slice";

type ActionReducerMapBuilderWithDetailsState =
  ActionReducerMapBuilder<DetailsState>;

export const getDetailsReducer = (
  builder: ActionReducerMapBuilderWithDetailsState
) => {
  builder.addCase(getDetailsAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getDetailsAsync.fulfilled,
    (state, action: PayloadAction<IDetailsData>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getDetailsAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
