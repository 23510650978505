import { createSlice } from "@reduxjs/toolkit";
import { IHomePage } from "src/@types/home";
import { HOME } from "./actions";
import { getHomePageReducer } from "./reducers";

export interface HomeState {
  isLoading: boolean;
  data: IHomePage;
}

const initialState: HomeState = {
  isLoading: false,
  data: null,
};

export const { reducer: home } = createSlice({
  name: HOME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getHomePageReducer(builder);
  },
});
