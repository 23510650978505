import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IReviewResponce } from "src/components/ReviewSection/types";
import { getReviewsAsync } from "./actions";
import { ReviewsState } from "./slice";

type ActionReducerMapBuilderWithReviewsState =
  ActionReducerMapBuilder<ReviewsState>;

export const getReviewsReducer = (
  builder: ActionReducerMapBuilderWithReviewsState
) => {
  builder.addCase(getReviewsAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getReviewsAsync.fulfilled,
    (state, action: PayloadAction<IReviewResponce>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getReviewsAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
