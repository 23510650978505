import { createSlice } from "@reduxjs/toolkit";
import { IElectricalRepairsPage } from "src/@types/electricalServices";
import { ELECTRICAL_REPAIRS_SLICE_NAME } from "./actions";
import { getElectricalRepairsPageReducer } from "./reducers";

export interface ElectricalRepairsState {
  isLoading: boolean;
  data: IElectricalRepairsPage;
}

const initialState: ElectricalRepairsState = {
  isLoading: false,
  data: null,
};

export const { reducer: electricalRepairs } = createSlice({
  name: ELECTRICAL_REPAIRS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getElectricalRepairsPageReducer(builder);
  },
});
