import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorBookNowState = (state: RootState) => state.bookNow;

export const selectorBookNow = createSelector(
  selectorBookNowState,
  (BookNowState) => BookNowState.data
);

export const selectorBookNowIsLoading = createSelector(
  selectorBookNowState,
  (BookNowState) => BookNowState.isLoading
);

export const selectorBookNowCouponState = (state: RootState) =>
  state.bookNowCoupon;

export const selectorBookNowCoupon = createSelector(
  selectorBookNowCouponState,
  (BookNowCouponState) => BookNowCouponState.data
);

export const selectorBookNowCouponIsLoading = createSelector(
  selectorBookNowCouponState,
  (BookNowCouponState) => BookNowCouponState.isLoading
);
