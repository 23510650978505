import { createSlice } from "@reduxjs/toolkit";
import { IApplyBelow } from "src/@types/postFormsData";
import { APPLY_BELOW } from "./actions";
import { postApplyBelowReducer } from "./reducers";

export interface applyBelowState {
  isLoading: boolean;
  data: IApplyBelow;
}

const initialState: applyBelowState = {
  isLoading: false,
  data: null,
};

export const { reducer: applyBelow } = createSlice({
  name: APPLY_BELOW,
  initialState,
  reducers: {},
  extraReducers(builder) {
    postApplyBelowReducer(builder);
  },
});
