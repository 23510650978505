import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IElectricalRepairsPage } from "src/@types/electricalServices";

export const ELECTRICAL_REPAIRS_SLICE_NAME = "electricalRepairs";

export const getElectricalRepairsPageAsync = createAsyncThunk(
  `${ELECTRICAL_REPAIRS_SLICE_NAME}/fetchElectricalRepairs`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IElectricalRepairsPage>(
        "/pages/electrical-repairs/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
