import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { ICareerItem } from "src/@types/career";
import { getCurrentCareerPostAsync } from "./actions";
import { CurrentCareerPostState } from "./slice";

type ActionReducerMapBuilderWithCurrentCareerPostState =
  ActionReducerMapBuilder<CurrentCareerPostState>;

export const getCurrentCareerPostReducer = (
  builder: ActionReducerMapBuilderWithCurrentCareerPostState
) => {
  builder.addCase(getCurrentCareerPostAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getCurrentCareerPostAsync.fulfilled,
    (state, action: PayloadAction<ICareerItem>) => {
      state.isLoading = false;
      state.post = action.payload;
    }
  );

  builder.addCase(getCurrentCareerPostAsync.rejected, (state) => {
    state.isLoading = false;
    state.post = null;
  });
};
