import React, { FC } from "react";
import { Link } from "../Link";
import { INavLink } from "../Header/types";

interface Props extends Omit<INavLink, "id"> {}

export const DropdownMenuListItem: FC<Props> = ({ label, path }) => (
  <li className="w-full border-b border-b-yellow-base last:border-none transition-colors duration-200 hover:text-yellow-base hover:font-bold">
    <Link
      className="w-full block py-2 px-3 hover:text-yellow-base hover:font-bold"
      href={path}
    >
      {label}
    </Link>
  </li>
);
