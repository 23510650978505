import { createSlice } from "@reduxjs/toolkit";
import { IFurnaceInstallationPage } from "src/@types/hvacServices";
import { FURNACE_INSTALLATION_SLICE_NAME } from "./actions";
import { getFurnaceInstallationPageReducer } from "./reducers";

export interface FurnaceInstallationState {
  isLoading: boolean;
  data: IFurnaceInstallationPage;
}

const initialState: FurnaceInstallationState = {
  isLoading: false,
  data: null,
};

export const { reducer: furnaceInstallation } = createSlice({
  name: FURNACE_INSTALLATION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getFurnaceInstallationPageReducer(builder);
  },
});
