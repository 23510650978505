import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { ICouponsResponse } from "src/@types/coupon";
import { getCouponsAsync } from "./actions";
import { ICouponsState } from "./slice";

type ActionReducerMapBuilderWithCouponsState =
  ActionReducerMapBuilder<ICouponsState>;

export const getCouponsReducer = (
  builder: ActionReducerMapBuilderWithCouponsState
) => {
  builder.addCase(getCouponsAsync.pending, (state) => {
    state.isLoading = true;
  });

builder.addCase(
  getCouponsAsync.fulfilled,
  (state, action: PayloadAction<ICouponsResponse[]>) => {
    state.isLoading = false;
    state.couponsData = action.payload; 
  }
);


  builder.addCase(getCouponsAsync.rejected, (state) => {
    state.isLoading = false;
    state.couponsData = null;
  });
};
