import cn from "classnames";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as LogoIcon } from "src/assets/icons/logo-yellow-base.svg";
import { ReactComponent as LogoMobIcon } from "src/assets/icons/footer-mob-logo.svg";
import { ContactsList } from "./ContactsList";
import { SocialLinks } from "./SocialLinks";
import { DEFAULT_FOOTER_DIVIDE_LINE_CLASSNAME } from "./constants";
import { Link } from "../Link";
import { FC } from "react";

interface Props {
  hiddenPhone?: boolean;
}

export const Footer: FC<Props> = ({ hiddenPhone }) => {
  const detailsData = useAppSelector(selectorDetails);

  const selectedContacts = detailsData?.CONTACTS?.slice(0, 3);

  const hiddenPageContacts = (detailsData?.CONTACTS || []).filter(
    (_, index) => index === 0 || index === 3
  );

  return (
    <footer className="wrapper">
      <div className="container">
        <div className="flex flex-wrap justify-between items-center gap-y-14 pb-10">
          <div className="flex flex-col items-center w-full lg:w-auto">
            <Link className="block mb-7" href="/">
              <div className="w-77.5 h-22 gl:w-100 gl:h-18.75">
                <LogoIcon className="hidden w-full h-full sm:block" />
                <LogoMobIcon className="w-full h-full sm:hidden" />
              </div>
            </Link>

            <div className="flex flex-col self-start">
              <span className="sm:text-gray-medium">LICENSE:</span>
              {Boolean(detailsData?.LICENSE_LIST?.length) &&
                detailsData.LICENSE_LIST.map(({ id, license }) => (
                  <span className="text-gray-medium" key={id}>
                    {license}
                  </span>
                ))}
            </div>
          </div>
          <SocialLinks
            workingHours={detailsData?.WORKING_HOURS}
            socialLinks={detailsData?.SOCIAL_LINKS}
          />

          {hiddenPhone ? (
            <ContactsList contacts={hiddenPageContacts} />
          ) : (
            <ContactsList contacts={selectedContacts} />
          )}
        </div>

        <div
          className={cn(
            DEFAULT_FOOTER_DIVIDE_LINE_CLASSNAME,
            "!mb-10 !gl:mb-6"
          )}
        />

        <div className="flex flex-wrap gap-10 text-base gl:items-center justify-between">
          <div className="text-gray-medium">
            <Link
              className="hover:underline duration-300"
              href={PATHNAMES.PRIVACY_POLICY}
            >
              Privacy Policy
            </Link>
            <span>&nbsp;and&nbsp;</span>
            <Link
              className="hover:underline duration-300"
              href={PATHNAMES.TERMS_OF_USE}
            >
              Terms of Use
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
