import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IAcRepairPage } from "src/@types/hvacServices";
import { getAcRepairPageAsync } from "./actions";
import { AcRepairState } from "./slice";

type ActionReducerMapBuilderWithAcRepairState =
  ActionReducerMapBuilder<AcRepairState>;

export const getAcRepairPageReducer = (
  builder: ActionReducerMapBuilderWithAcRepairState
) => {
  builder.addCase(getAcRepairPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getAcRepairPageAsync.fulfilled,
    (state, action: PayloadAction<IAcRepairPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getAcRepairPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
