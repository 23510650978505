import { IFormField } from "src/@types/form";

export const BOOK_NOW_FORM_FIELDS: IFormField[] = [
  {
    id: crypto.randomUUID(),
    fieldId: "bookNowName",
    placeholder: "Name\u002A",
    name: "name",
    type: "text",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "bookNowEmail",
    placeholder: "Email\u002A",
    name: "email",
    type: "email",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "bookNowPhone",
    placeholder: "Phone\u002A",
    name: "phone",
    type: "tel",
    required: true,
  },
];

export const BOOK_NOW_DROP_DOWN_LIST = [
  {
    id: crypto.randomUUID(),
    label: "HVAC Service",
  },
  {
    id: crypto.randomUUID(),
    label: "Electrical Service",
  },
  {
    id: crypto.randomUUID(),
    label: "Appliance Service",
  },
];
