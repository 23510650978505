import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { newsTips } from "./newsTips/slice";
import { currentNewsTipsPost } from "./currentNewsTipsPost/slice";
import { career } from "./career/slice";
import { currentCareerPost } from "./currentCareerPost/slice";
import { coupons } from "./coupons/slice";
import { reviews } from "./reviews/slice";
import { details } from "./details/slice";
import { bookNow, bookNowCoupon } from "./bookNow/slice";
import { getInTouch } from "./getInTouch/slice";
import { applyBelow } from "./applyBelow/slice";
import { serviceArea } from "./serviceArea/slice";
import { acRepair } from "./services/hvac/acRepair/slice";
import { furnaceRepair } from "./services/hvac/furnaceRepair/slice";
import { maintenance } from "./services/hvac/maintenance/slice";
import { acInstallation } from "./services/hvac/acInstallation/slice";
import { furnaceInstallation } from "./services/hvac/furnaceInstallation/slice";
import { heatPumpInstallation } from "./services/hvac/heatPumpInstallation/slice";
import { electricalRepairs } from "./services/electrical/electricalRepairs/slice";
import { roughWiring } from "./services/electrical/roughWiring/slice";
import { breakerInstallation } from "./services/electrical/breakerInstallation/slice";
import { panelInstallation } from "./services/electrical/panelInstallation/slice";
import { residentialServices } from "./services/electrical/residentialServices/slice";
import { evChargerInstallation } from "./services/electrical/evChargerInstallation/slice";
import { applianceInstallation } from "./services/appliance/installation/slice";
import { applianceMaintenance } from "./services/appliance/maintenance/slice";
import { applianceMajorRepair } from "./services/appliance/majorRepair/slice";
import { membershipForm } from "./membershipForm/slice";
import { membershipPage } from "./membershipPage/slice";
import { home } from "./home/slice";
import { newsTipsBookNow } from "./newsTipsBookNow/slice";
import { serviceAreaRegion } from "./serviceAreaRegion/slice";

export const store = configureStore({
  reducer: combineReducers({
    newsTips,
    currentNewsTipsPost,
    career,
    currentCareerPost,
    coupons,
    bookNowCoupon,
    reviews,
    details,
    bookNow,
    getInTouch,
    applyBelow,
    newsTipsBookNow,

    serviceArea,
    serviceAreaRegion,

    acRepair,
    furnaceRepair,
    maintenance,
    acInstallation,
    furnaceInstallation,
    heatPumpInstallation,

    electricalRepairs,
    roughWiring,
    breakerInstallation,
    panelInstallation,
    residentialServices,
    evChargerInstallation,

    applianceInstallation,
    applianceMaintenance,
    applianceMajorRepair,

    membershipForm,
    membershipPage,
    home,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
