import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IMembershipPage } from "src/@types/membership";

export const MEMBERSHIP_PAGE_SLICE_NAME = "membershipPage";

export const getMembershipPageAsync = createAsyncThunk(
  `${MEMBERSHIP_PAGE_SLICE_NAME}/fetchMembershipPage`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IMembershipPage>(
        "/pages/membership/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
