import { IFormField } from "src/@types/form";

export const CONTACT_FORM_FIELDS: IFormField[] = [
  {
    id: crypto.randomUUID(),
    fieldId: "name",
    placeholder: "Name\u002A",
    name: "name",
    type: "text",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "contactPhone",
    placeholder: "Phone\u002A",
    name: "phone",
    type: "tel",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "contactEmail",
    placeholder: "Email\u002A",
    name: "email",
    type: "email",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "contactAddress",
    placeholder: "Address\u002A",
    name: "address",
    type: "text",
    required: true,
    isActiveValidation: false,
  },
];
