import { createSlice } from "@reduxjs/toolkit";
import { ICareerItem } from "src/@types/career";
import { CURRENT_CAREER_POST_SLICE_NAME } from "./actions";
import { getCurrentCareerPostReducer } from "./reducers";

export interface CurrentCareerPostState {
  isLoading: boolean;
  post: ICareerItem;
}

const initialState: CurrentCareerPostState = {
  isLoading: false,
  post: null,
};

export const { reducer: currentCareerPost } = createSlice({
  name: CURRENT_CAREER_POST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getCurrentCareerPostReducer(builder);
  },
});
