import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { getHomePageAsync } from "./actions";
import { IHomePage } from "src/@types/home";
import { HomeState } from "./slice";


type ActionReducerMapBuilderWithHomeState =
  ActionReducerMapBuilder<HomeState>;

export const getHomePageReducer = (
  builder: ActionReducerMapBuilderWithHomeState
) => {
  builder.addCase(getHomePageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getHomePageAsync.fulfilled,
    (state, action: PayloadAction<IHomePage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getHomePageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
