import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IApplyBelow } from "src/@types/postFormsData";
import { applyBelowState } from "./slice";
import { postApplyBelow } from "./actions";

type ActionReducerMapBuilderWithApplyBelowState =
  ActionReducerMapBuilder<applyBelowState>;

export const postApplyBelowReducer = (
  builder: ActionReducerMapBuilderWithApplyBelowState
) => {
  builder.addCase(postApplyBelow.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    postApplyBelow.fulfilled,
    (state, action: PayloadAction<IApplyBelow>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(postApplyBelow.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
