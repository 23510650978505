import { FC } from "react";
// import cn from "classnames";
import { Section } from "src/components/Section";
import { ContentOverlay } from "src/components/ContentOverlay";
import { ISectionWithImage } from "src/@types";
// import { DEFAULT_YELLOW_BLUR } from "./HeroSwiper/constants";

interface Props extends ISectionWithImage {}

export const OurStory: FC<Props> = ({ image }) => (
  <ContentOverlay
    className="relative hidden md:flex lg:py-40"
    bg={image}
    bgPositionClassName="object-center"
  >
    <Section className="wrapper" title="our story">
      <p className="max-w-160 text-base md:text-xl font-manrope !leading-8 ">
        Founded in 2017,&nbsp;
        <span className="text-base md:text-xl text-yellow-base font-bold ">
          Smartchoice Service
        </span>
        &nbsp; was established with a vision to deliver exceptional service and
        build long-lasting relationships with our customers. Over the years, we
        have grown our expertise and expanded our service offerings to meet the
        evolving needs of our clients. Whether it's a minor appliance repair, a
        comprehensive HVAC installation, or a complex electrical issue, we are
        here to help.
      </p>
    </Section>
    {/* <div className={cn(DEFAULT_YELLOW_BLUR, "top-0")} />

    <div
      className={cn(DEFAULT_YELLOW_BLUR, "top-0 !left-0 !-translate-x-1/2")}
    /> */}
  </ContentOverlay>
);
