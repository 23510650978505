import { createSlice } from "@reduxjs/toolkit";
import { IApplianceRepairPage } from "src/@types/appliance";
import { getApplianceMajorRepairPageReducer } from "./reducers";
import { APPLIANCE } from "../installation/actions";

export interface ApplianceMajorRepairState {
  isLoading: boolean;
  data: IApplianceRepairPage;
}

const initialState: ApplianceMajorRepairState = {
  isLoading: false,
  data: null,
};

export const { reducer: applianceMajorRepair } = createSlice({
  name: APPLIANCE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getApplianceMajorRepairPageReducer(builder);
  },
});
