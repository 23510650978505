import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IFurnaceRepairPage } from "src/@types/hvacServices";
import { getFurnaceRepairPageAsync } from "./actions";
import { FurnaceRepairState } from "./slice";

type ActionReducerMapBuilderWithFurnaceRepairState =
  ActionReducerMapBuilder<FurnaceRepairState>;

export const getFurnaceRepairPageReducer = (
  builder: ActionReducerMapBuilderWithFurnaceRepairState
) => {
  builder.addCase(getFurnaceRepairPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getFurnaceRepairPageAsync.fulfilled,
    (state, action: PayloadAction<IFurnaceRepairPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getFurnaceRepairPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
