import { createSlice } from "@reduxjs/toolkit";
import { IPostFormsData } from "src/@types/postFormsData";
import { MEMBERSHIP_SLICE_NAME } from "./actions";
import { postMembershipReducer } from "./reducers";

export interface postMembershipState {
  isLoading: boolean;
  data: IPostFormsData;
}

const initialState: postMembershipState = {
  isLoading: false,
  data: null,
};

export const { reducer: membershipForm } = createSlice({
  name: MEMBERSHIP_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    postMembershipReducer(builder);
  },
});
