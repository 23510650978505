import React, { FC } from "react";
import cn from "classnames";
import { Link } from "src/components/Link";
import { INavMenu } from "src/components/Header/types";
import { PATHNAMES } from "src/constants/routes";
import { SubMenuList } from "./SubMenuList";

interface Props extends INavMenu {
  openedService: number | string;
  setOpenedService: (id: number | string) => void;
}

export const MobNavMenuListItem: FC<Props> = ({
  id,
  label,
  path,
  services,
  openedService,
  setOpenedService,
}) => {
  const onClick = () => setOpenedService(openedService === id ? null : id);

  return (
    <li className="cursor-pointer" onClick={onClick}>
      {path === PATHNAMES.SERVICE_AREA ? (
        <Link
          href={path}
          className={cn(
            "block py-3 px-5 hover:bg-black-base hover:text-yellow-base transition-colors duration-300"
          )}
        >
          {label}
        </Link>
      ) : (
        <div
          className={cn(
            "py-3 px-5 hover:bg-black-base hover:text-yellow-base transition-colors duration-300",
            {
              "bg-black-base text-yellow-base": openedService === id,
            }
          )}
        >
          {label}
        </div>
      )}

      <SubMenuList items={services} isOpen={openedService === id} />
    </li>
  );
};
