import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IServiceAreaRegionPage } from "src/@types/serviceArea";
import { getServiceAreaRegionAsync } from "./actions";
import { ServiceAreaRegionState } from "./slice";

type ActionReducerMapBuilderWithServiceAreaRegionState =
  ActionReducerMapBuilder<ServiceAreaRegionState>;

export const getServiceAreaRegionReducer = (
  builder: ActionReducerMapBuilderWithServiceAreaRegionState
) => {
  builder.addCase(getServiceAreaRegionAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getServiceAreaRegionAsync.fulfilled,
    (state, action: PayloadAction<IServiceAreaRegionPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getServiceAreaRegionAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
