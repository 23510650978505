import { FormFieldVariants } from "./types";

export const FORM_FIELD_STYLE_VARIANTS = {
  [FormFieldVariants.PRIMARY]: "",
};

export const TEXT_INPUT_STYLE_VARIANTS = {
  [FormFieldVariants.PRIMARY]:
    "outline-none px-4 pb-4 w-full text-xl text-gray-light font-manrope bg-transparent border-b border-yellow-base border-opacity-40 placeholder:text-opacity-60 focus:border-opacity-100",
};

export const FILE_INPUT_STYLE_VARIANTS = {
  [FormFieldVariants.PRIMARY]: ""
}

export const LABEL_STYLE_VARIANTS = {
  [FormFieldVariants.PRIMARY]: "",
};
