import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IElectricalRepairsPage } from "src/@types/electricalServices";
import { getElectricalRepairsPageAsync } from "./actions";
import { ElectricalRepairsState } from "./slice";

type ActionReducerMapBuilderWithElectricalRepairsState =
  ActionReducerMapBuilder<ElectricalRepairsState>;

export const getElectricalRepairsPageReducer = (
  builder: ActionReducerMapBuilderWithElectricalRepairsState
) => {
  builder.addCase(getElectricalRepairsPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getElectricalRepairsPageAsync.fulfilled,
    (state, action: PayloadAction<IElectricalRepairsPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getElectricalRepairsPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
