import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IServiceAreaRegionPage } from "src/@types/serviceArea";

export const SERVICE_AREA_REGION_SLICE_NAME = "serviceAreaRegion";

export const getServiceAreaRegionAsync = createAsyncThunk(
  `${SERVICE_AREA_REGION_SLICE_NAME}/fetchServiceAreaRegion`,
  async (slug: string, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IServiceAreaRegionPage>(
        `pages/service-area/${slug}/`
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
