import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IReviewResponce } from "src/components/ReviewSection/types";

export const REVIEWS_SLICE_NAME = "reviews";

export const getReviewsAsync = createAsyncThunk(
  `${REVIEWS_SLICE_NAME}/fetchReviews`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IReviewResponce>(`/reviews/`);

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
