import { createSlice } from "@reduxjs/toolkit";
import { IServiceAreaResponse } from "src/@types/serviceArea";
import { SERVICE_AREA } from "./actions";
import { getServiceAreaReducer } from "./reducers";

export interface IServiceAreaState {
  isLoading: boolean;
  serviceAreaData: IServiceAreaResponse;
}
const initialState: IServiceAreaState = {
  isLoading: false,
  serviceAreaData: null,
};

export const { reducer: serviceArea } = createSlice({
  name: SERVICE_AREA,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getServiceAreaReducer(builder);
  },
});
