import { FC, ReactNode } from "react";
import cn from "classnames";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { GoogleMaps } from "../GoogleMaps";
import { MobHeader } from "../MobHeader";

interface Props {
  children: ReactNode;
  className?: string;
  mainClassName?: string;
  isShownHeader?: boolean;
  isShownFooter?: boolean;
  isShownMaps?: boolean;
  hiddenPage?: boolean;
}

export const PageWrapper: FC<Props> = ({
  children,
  className,
  mainClassName,
  isShownHeader,
  isShownFooter,
  isShownMaps = true,
  hiddenPage,
}) => (
  <div className={cn("flex flex-col h-screen", className)}>
    <Header hiddenPhone={hiddenPage} />
    <MobHeader hiddenPage={hiddenPage} />
    <main className={cn("flex-1 mt-16", mainClassName)}>{children}</main>

    {isShownMaps && <GoogleMaps />}

    <Footer hiddenPhone={hiddenPage} />
  </div>
);
