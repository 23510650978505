import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IBookNowData } from "src/@types/postFormsData";
import { bookNowState } from "./slice";
import { postBookNow, postBookNowCoupon } from "./actions";


type ActionReducerMapBuilderWithbookNowState =
  ActionReducerMapBuilder<bookNowState>;

export const postBookNowReducer = (
  builder: ActionReducerMapBuilderWithbookNowState
) => {
  builder.addCase(postBookNow.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    postBookNow.fulfilled,
    (state, action: PayloadAction<IBookNowData>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(postBookNow.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};



type ActionReducerMapBuilderWithbookNowCouponState =
  ActionReducerMapBuilder<bookNowState>;

export const postBookNowCouponReducer = (
  builder: ActionReducerMapBuilderWithbookNowCouponState
) => {
  builder.addCase(postBookNowCoupon.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    postBookNowCoupon.fulfilled,
    (state, action: PayloadAction<IBookNowData>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(postBookNowCoupon.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
