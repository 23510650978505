import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Control, useController } from "react-hook-form";
import cn from "classnames";
import { ReactComponent as UploadIcon } from "src/assets/icons/arrow-down-upload-white-base.svg";
import { NotificationService } from "src/helpers/notifications";

interface PhotoUploadProps {
  name: string;
  control: Control<any>;
  acceptTypes: string;
  className?: string;
  defaultLabel: string;
  changeLabel: string;
  errorMessage?: string;
  maxSize: number;
  isValidate?: boolean;
}

// Тип для рефа, який містить метод `clearFile`
export interface CustomFileUploadHandle {
  clearFile: () => void;
}

// Використовуємо forwardRef для передачі рефа
export const CustomFileUpload = forwardRef<
  CustomFileUploadHandle,
  PhotoUploadProps
>(
  (
    {
      name,
      control,
      acceptTypes,
      className,
      defaultLabel,
      changeLabel,
      errorMessage,
      maxSize,
      isValidate = true,
    },
    ref
  ) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      rules: {
        required: isValidate ? errorMessage || "Required field" : false, 
      },
    });

    const [previewTitle, setPreviewTitle] = useState<string | null>(null);
    const hiddenInputRef = useRef<HTMLInputElement | null>(null);

    const handleUploadedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;
      if (file) {
        if (file.size > maxSize) {
          field.onChange(null);
          setPreviewTitle(null);
          NotificationService.error(
            `File size should not exceed ${maxSize / (1024 * 1024)} MB.`
          );
          return;
        }

        field.onChange(file);
        setPreviewTitle(file.name);
      }
    };

    const onUpload = () => {
      hiddenInputRef.current?.click();
    };

    const clearFile = () => {
      field.onChange(null);
      setPreviewTitle(null);
      if (hiddenInputRef.current) {
        hiddenInputRef.current.value = "";
      }
    };

    // Використовуємо useImperativeHandle для того, щоб clearFile був доступний зовні
    useImperativeHandle(ref, () => ({
      clearFile,
    }));

    const uploadButtonLabel = previewTitle ? changeLabel : defaultLabel;

    return (
      <div className="relative p-px bg-gradient-to-r from-goldGradientOlive to-goldGradientEnd rounded-10">
        <div className="flex  items-center justify-center bg-black-base rounded-10">
          <button
            type="button"
            onClick={onUpload}
            className={cn(
              "flex gap-x-2.5 w-full px-3 py-2 cursor-pointer",
              className
            )}
          >
            <p className="text-xl font-manrope">{uploadButtonLabel}</p>
            <UploadIcon />
          </button>

          <input
            id="upload_photo"
            hidden
            type="file"
            accept={acceptTypes}
            onChange={handleUploadedFile}
            ref={hiddenInputRef}
          />
          {previewTitle && (
            <div className="pr-3 py-2">
              <p className="max-w-30 text-base font-manrope line-clamp-1">
                {previewTitle}
              </p>
            </div>
          )}
        </div>
        {error && (
          <div className="absolute -bottom-6 left-4 sm:-bottom-6 text-13 text-red-600">
            {error.message}
          </div>
        )}
      </div>
    );
  }
);
