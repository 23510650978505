import { FC } from "react";
import { MediaPlayer, MediaProvider, Poster } from "@vidstack/react";
// import cn from "classnames";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
import { useModal } from "src/hooks/useModal";
import { useAppSelector } from "src/hooks/redux";
import { selectorHome } from "src/redux/home/selectors";
import { getImageUrl } from "src/utils/getImageUrl";
import { BookNowModal } from "src/components/BookNowModal";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ReactComponent as BookIcon } from "src/assets/icons/book-black-base.svg";
import VideoPoster from "src/assets/images/home/video-poster.webp";
// import { ReactComponent as ArrowBlackBase } from "src/assets/icons/arrow-right-black-base.svg";
// import { DEFAULT_HERO_SWIPER_ARROW_CLASSNAME } from "./constants";
import "swiper/css";

export const HeroSwiper: FC = () => {
  const pageData = useAppSelector(selectorHome);
  const slides = pageData?.hero_swiper || [];
  const videoSlide = slides[0];

  const bookNow = useModal();

  return (
    <div className="relative w-full">
      <div className="absolute top-0 left-0 z-20 w-full h-full bg-gradient-to-r from-black-base to-black-base opacity-60 lg:!to-transparent lg:opacity-1" />

      {/* <MediaPlayer
        src={{ src: getImageUrl(videoSlide?.src), type: "video/mp4" }}
        className="video-container"
        autoPlay
        playsInline
        muted
        loop
        poster={VideoPoster}
        posterLoad="eager"
        load="eager"
      >
        <MediaProvider>
          <Poster src={VideoPoster} asChild />
        </MediaProvider>
      </MediaPlayer> */}
      <video
        className="video-container object-cover"
        autoPlay
        muted
        loop
        playsInline
        poster={VideoPoster}
        preload="auto"
      >
        <source src={getImageUrl(videoSlide?.src)} />
      </video>

      <div className="absolute top-1/2 left-5 -translate-y-1/2 z-30 lg:left-32">
        <div
          className="text-40 sm:text-5xl mb-6 leading-snug text-white"
          dangerouslySetInnerHTML={{ __html: videoSlide?.text.html }}
        />

        <Button
          className="flex items-center gap-2 min-w-40 border-goldGradientOlive bg-gradient-to-b from-heroBtnStart to-heroEnd lg:py-3"
          type="button"
          variant={ButtonVariants.PRIMARY}
          onClick={bookNow.openModal}
        >
          <BookIcon className="min-w-5 min-h-5" />

          <span className="text-18 font-extrabold whitespace-nowrap">
            Book now
          </span>
        </Button>
      </div>
      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
    </div>
  );
};
// <div className="relative w-full">
//   <Swiper
//     className="relative w-full"
//     modules={[Navigation]}
//     pagination={{ clickable: true }}
//     slidesPerView={1}
//     navigation={{
//       nextEl: ".custom-swiper-hero-button-next",
//       prevEl: ".custom-swiper-hero-button-prev",
//     }}
//     loop={true}
//   >
//     {slides.map(({ id, alt, slide_type, src, text }) => (
//       <SwiperSlide className="w-full h-full " key={id}>
//         <div className="relative">
//           <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black-base to-transparent" />

//           {slide_type === "video" ? (
//             <MediaPlayer
//               className="w-full h-full max-h-screen aspect-video min-h-143.5"
//               autoPlay
//               muted
//               loop
//               playsInline
//               src={getImageUrl(src)}
//             >
//               <MediaProvider className="media-player  w-full h-full min-h-143.5" />
//             </MediaPlayer>
//           ) : (
//             <img loding='lazy'
//               className="w-full max-h-screen object-cover min-h-143.5"
//               src={getImageUrl(src)}
//               alt={alt}
//             />
//           )}

//           <div className="absolute top-1/2 left-5 -translate-y-1/2 lg:left-80">
//             <div
//               className="text-40 sm:text-5xl mb-6 leading-snug"
//               dangerouslySetInnerHTML={{ __html: text.html }}
//             />

//             <Button
//               type="button"
//               variant={ButtonVariants.PRIMARY}
//               onClick={bookNow.openModal}
//             >
//               <span className="whitespace-nowrap">Book now</span>
//             </Button>
//           </div>
//         </div>
//       </SwiperSlide>
//     ))}
//   </Swiper>

//   <div
//     className={cn(
//       DEFAULT_HERO_SWIPER_ARROW_CLASSNAME,
//       "custom-swiper-hero-button-prev left-5 lg:left-15 rotate-180 "
//     )}
//   >
//     <ArrowBlackBase />
//   </div>

//   <div
//     className={cn(
//       DEFAULT_HERO_SWIPER_ARROW_CLASSNAME,
//       "custom-swiper-hero-button-next right-5 lg:right-15"
//     )}
//   >
//     <ArrowBlackBase />
//   </div>

//   <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
// </div>
