import React, { FC } from "react";
import cn from "classnames";
import { Link } from "src/components/Link";
import { INavMenu } from "src/components/Header/types";

interface Props {
  className?: string;
  isOpen?: boolean;
  items?: INavMenu[];
}

export const SubMenuList: FC<Props> = ({ items, isOpen }) => {
  if (!items?.length) return null;

  return (
    <div
      className={cn(
        "transition-all duration-700 transform overflow-hidden last:border-b last:border-b-black-base",
        {
          "max-h-0 opacity-0": !isOpen,
          "max-h-138 opacity-100 pl-8": isOpen,
        }
      )}
    >
      <div className="flex flex-col">
        {items.map((item) => (
          <Link
            key={item.id}
            className="w-full py-3 px-5 hover:font-bold"
            href={item.path}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
