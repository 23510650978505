import { createSlice } from "@reduxjs/toolkit";
import { IGetInTouch } from "src/@types/postFormsData";
import { GET_IN_TOUCH } from "./actions";
import { postgetInTouchReducer } from "./reducers";

export interface getInTouchState {
  isLoading: boolean;
  data: IGetInTouch;
}

const initialState: getInTouchState = {
  isLoading: false,
  data: null,
};

export const { reducer: getInTouch } = createSlice({
  name: GET_IN_TOUCH,
  initialState,
  reducers: {},
  extraReducers(builder) {
    postgetInTouchReducer(builder);
  },
});
