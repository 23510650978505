import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IRoughWiringPage } from "src/@types/electricalServices";
import { getRoughWiringPageAsync } from "./actions";
import { RoughWiringState } from "./slice";

type ActionReducerMapBuilderWithRoughWiringState =
  ActionReducerMapBuilder<RoughWiringState>;

export const getRoughWiringPageReducer = (
  builder: ActionReducerMapBuilderWithRoughWiringState
) => {
  builder.addCase(getRoughWiringPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getRoughWiringPageAsync.fulfilled,
    (state, action: PayloadAction<IRoughWiringPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getRoughWiringPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
