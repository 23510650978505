import { FC, useState } from "react";
import { ReactComponent as MobLogoIcon } from "src/assets/icons/mob-logo-yellow.svg";
import { ContactButtons } from "./ContactButtons";
import { MobNavMenu } from "./MobNavMenu";
import { Link } from "../Link";

interface Props {
  hiddenPage?: boolean;
}

export const MobHeader: FC<Props> = ({ hiddenPage }) => {
  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState(false);

  return (
    <header className="fixed top-0 lg:hidden bg-black-base w-full z-40">
      <div className="container flex justify-between items-center py-4 px-5">
        <Link href="/">
          <MobLogoIcon />
        </Link>
        <ContactButtons
          setIsOpenDropdownMenu={setIsOpenDropdownMenu}
          hiddenPage={hiddenPage}
        />
      </div>

      <MobNavMenu
        hiddenPage={hiddenPage}
        isOpenDropdownMenu={isOpenDropdownMenu}
        setIsOpenDropdownMenu={setIsOpenDropdownMenu}
      />
    </header>
  );
};
