import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IAcRepairPage } from "src/@types/hvacServices";

export const AC_REPAIR_SLICE_NAME = "acRepair";

export const getAcRepairPageAsync = createAsyncThunk(
  `${AC_REPAIR_SLICE_NAME}/fetchAcRepair`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IAcRepairPage>("/pages/ac-repair/");

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
