import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationService } from "src/helpers/notifications";
import { instance } from "src/services/api-client";
import { IBookNowData } from "src/@types/postFormsData";

export const BOOK_NOW = "bookNow";

export const postBookNow = createAsyncThunk(
  `${BOOK_NOW}/postBookNow`,
  async (bookNowData: IBookNowData, { rejectWithValue }) => {
    try {
      const { data } = await instance.post<IBookNowData>(
        `/book-now/`,
        bookNowData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return data;
    } catch ({ response }) {
      console.log(response);

      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);

export const postBookNowCoupon = createAsyncThunk(
  `${BOOK_NOW}/postBookNowCoupon`,
  async (bookNowData: IBookNowData, { rejectWithValue }) => {
    try {
      const { data } = await instance.post<IBookNowData>(
        `/book-now-coupons/`,
        bookNowData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
