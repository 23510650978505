import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IAppliance } from "src/@types/appliance";

export const APPLIANCE = "appliance";

export const getApplianceInstallationPageAsync = createAsyncThunk(
  `${APPLIANCE}/fetchInstallation`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IAppliance>(
        `/pages/appliance/installation/`
      ); 

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
