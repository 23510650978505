import { PayloadAction, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { INewsTipsItem } from "src/@types/newsTips";
import { IResponseWithPagination } from "src/@types/api";
import { NewsTipsState } from "./slice";
import { getNewsTipsAsync } from "./actions";

type ActionReducerMapBuilderWithNewsTipsState =
  ActionReducerMapBuilder<NewsTipsState>;

export const getNewsTipsReducer = (
  builder: ActionReducerMapBuilderWithNewsTipsState
) => {
  builder.addCase(getNewsTipsAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getNewsTipsAsync.fulfilled,
    (state, action: PayloadAction<IResponseWithPagination<INewsTipsItem>>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getNewsTipsAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
