import { createSlice } from "@reduxjs/toolkit";
import { IFurnaceRepairPage } from "src/@types/hvacServices";
import { FURNACE_REPAIR_SLICE_NAME } from "./actions";
import { getFurnaceRepairPageReducer } from "./reducers";

export interface FurnaceRepairState {
  isLoading: boolean;
  data: IFurnaceRepairPage;
}

const initialState: FurnaceRepairState = {
  isLoading: false,
  data: null,
};

export const { reducer: furnaceRepair } = createSlice({
  name: FURNACE_REPAIR_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getFurnaceRepairPageReducer(builder);
  },
});
