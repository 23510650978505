import { createSlice } from "@reduxjs/toolkit";
import { IAcInstallationPage } from "src/@types/hvacServices";
import { AC_INSTALLATION_SLICE_NAME } from "./actions";
import { getAcInstallationPageReducer } from "./reducers";

export interface AcInstallationState {
  isLoading: boolean;
  data: IAcInstallationPage;
}

const initialState: AcInstallationState = {
  isLoading: false,
  data: null,
};

export const { reducer: acInstallation } = createSlice({
  name: AC_INSTALLATION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getAcInstallationPageReducer(builder);
  },
});
