import { createSlice } from "@reduxjs/toolkit";
import { IHeatPumpInstallationPage } from "src/@types/hvacServices";
import { HEAT_PUMP_INSTALLATION_SLICE_NAME } from "./actions";
import { getHeatPumpInstallationPageReducer } from "./reducers";

export interface HeatPumpInstallationState {
  isLoading: boolean;
  data: IHeatPumpInstallationPage;
}

const initialState: HeatPumpInstallationState = {
  isLoading: false,
  data: null,
};

export const { reducer: heatPumpInstallation } = createSlice({
  name: HEAT_PUMP_INSTALLATION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getHeatPumpInstallationPageReducer(builder);
  },
});
