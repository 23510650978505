import { createSlice } from "@reduxjs/toolkit";
import { IResponseWithPagination } from "src/@types/api";
import { INewsTipsItem } from "src/@types/newsTips";
import { NEWSTIPS_SLICE_NAME } from "./actions";
import { getNewsTipsReducer } from "./reducers";

export interface NewsTipsState {
  isLoading: boolean;
  data: IResponseWithPagination<INewsTipsItem>;
}

const initialState: NewsTipsState = {
  isLoading: false,
  data: null,
};

export const { reducer: newsTips } = createSlice({
  name: NEWSTIPS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getNewsTipsReducer(builder);
  },
});
