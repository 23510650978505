import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IApplianceRepairPage } from "src/@types/appliance";
import { ApplianceMajorRepairState } from "./slice";
import { getApplianceMajorRepairPageAsync } from "./actions";

type ActionReducerMapBuilderWithApplianceMajorRepairState =
  ActionReducerMapBuilder<ApplianceMajorRepairState>;

export const getApplianceMajorRepairPageReducer = (
  builder: ActionReducerMapBuilderWithApplianceMajorRepairState
) => {
  builder.addCase(getApplianceMajorRepairPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getApplianceMajorRepairPageAsync.fulfilled,
    (state, action: PayloadAction<IApplianceRepairPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getApplianceMajorRepairPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
