import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IMaintenace } from "src/@types/appliance";
import { ApplianceMaintenanceState } from "./slice";
import { getApplianceMaintenancePageAsync } from "./actions";

type ActionReducerMapBuilderWithApplianceMaintenanceState =
  ActionReducerMapBuilder<ApplianceMaintenanceState>;

export const getApplianceMaintenancePageReducer = (
  builder: ActionReducerMapBuilderWithApplianceMaintenanceState
) => {
  builder.addCase(getApplianceMaintenancePageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getApplianceMaintenancePageAsync.fulfilled,
    (state, action: PayloadAction<IMaintenace>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getApplianceMaintenancePageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
