import { createSlice } from "@reduxjs/toolkit";
import { IMembershipPage } from "src/@types/membership";
import { MEMBERSHIP_PAGE_SLICE_NAME } from "./actions";
import { getMembershipPageReducer } from "./reducers";

export interface MembershipState {
  isLoading: boolean;
  data: IMembershipPage;
}

const initialState: MembershipState = {
  isLoading: false,
  data: null,
};

export const { reducer: membershipPage } = createSlice({
  name: MEMBERSHIP_PAGE_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getMembershipPageReducer(builder);
  },
});
