import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IAcInstallationPage } from "src/@types/hvacServices";

export const AC_INSTALLATION_SLICE_NAME = "acInstallation";

export const getAcInstallationPageAsync = createAsyncThunk(
  `${AC_INSTALLATION_SLICE_NAME}/fetchAcInstallation`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IAcInstallationPage>(
        "/pages/ac-installation/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
