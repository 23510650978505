import { FC, useEffect } from "react";
import { SuperSEO } from "react-super-seo";
import loadable from "@loadable/component";
import { getHomePageAsync } from "src/redux/home/actions";
import { useModal } from "src/hooks/useModal";
import { selectorHome, selectorHomesIsLoading } from "src/redux/home/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { GetInTouch } from "src/components/GetInTouchForm";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
import { OurStory } from "src/page-components/Home/OurStory";
import { HeroSwiper } from "src/page-components/Home/HeroSwiper";

// Динамічні імпорти для секцій
// const HeroSwiper = loadable(() =>
//   import("src/page-components/Home/HeroSwiper").then((mod) => mod.HeroSwiper)
// );
const ServiceInNumber = loadable(() =>
  import("src/page-components/Home/ServiceInNumber").then(
    (mod) => mod.ServiceInNumber
  )
);
const BrandsWeService = loadable(() =>
  import("src/page-components/Home/BrandsWeService").then(
    (mod) => mod.BrandsWeService
  )
);
const OurServices = loadable(() =>
  import("src/page-components/Home/OurServices").then((mod) => mod.OurServices)
);
const WhySmartchoice = loadable(() =>
  import("src/page-components/Home/WhySmartchoice").then(
    (mod) => mod.WhySmartchoice
  )
);
const SpecialOffers = loadable(() =>
  import("src/page-components/Home/SpecialOffers").then(
    (mod) => mod.SpecialOffers
  )
);
const OurCommitment = loadable(() =>
  import("src/page-components/Home/OurCommitment").then(
    (mod) => mod.OurCommitment
  )
);
const ContactUs = loadable(() =>
  import("src/page-components/Home/ContactUs").then((mod) => mod.ContactUs)
);
const AppointmentBooking = loadable(() =>
  import("src/components/AppointmentBooking").then(
    (mod) => mod.AppointmentBooking
  )
);
const OtherServices = loadable(() =>
  import("src/components/OtherServices").then((mod) => mod.OtherServices)
);
const AboutUs = loadable(() =>
  import("src/page-components/Home/AboutUs").then((mod) => mod.AboutUs)
);
const NewsTips = loadable(() =>
  import("src/page-components/Home/NewsTips").then((mod) => mod.NewsTips)
);
const ReviewSection = loadable(() =>
  import("src/components/ReviewSection").then((mod) => mod.ReviewSection)
);
// const OurStory = loadable(() => import("src/page-components/Home/OurStory")).then(mod => mod.OurStory);
const BookNowModal = loadable(() =>
  import("src/components/BookNowModal").then((mod) => mod.BookNowModal)
);
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const HiddenHome: FC = () => {
  const bookNow = useModal();

  const dispatch = useAppDispatch();
  const pageData = useAppSelector(selectorHome);
  const contactsData = useAppSelector(selectorDetails);
  const isLoading = useAppSelector(selectorHomesIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getHomePageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper hiddenPage={true}>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <HeroSwiper />
        )}

        <div className="container">
          {/* <div className={cn(DEFAULT_YELLOW_BLUR, "!right-0")} /> */}
          <OtherServices />

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 !left-0 -translate-x-1/3 sm:!-translate-x-3/4"
              )}
            /> */}
            <AppointmentBooking
              title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
              image={pageData?.appointment_booking}
              onButtonClick={bookNow.openModal}
              phone={contactsData?.CONTACTS[3]}
            />
          </div>

          <AboutUs image={pageData?.about_us} />
        </div>

        <OurStory image={pageData?.our_story} />

        <div className="container">
          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-1/4 !left-0 -translate-x-1/3"
              )}
            /> */}
            <OurServices />
          </div>

          <WhySmartchoice />
        </div>

        <OurCommitment image={pageData?.our_commitment} />

        <div className="container">
          <SpecialOffers />
          <GetInTouch />

          <div className="hidden lg:block">
            <ContactUs isHiddenPage={true} />
          </div>

          <ReviewSection />
          <BrandsWeService />
          <NewsTips />
          <ServiceInNumber />

          <BookNowModal
            isOpen={bookNow.isOpenModal}
            onClose={bookNow.closeModal}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default HiddenHome;
