import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IBreakerInstallationPage } from "src/@types/electricalServices";

export const BREAKER_INSTALLATION_SLICE_NAME = "breakerInstallation";

export const getBreakerInstallationPageAsync = createAsyncThunk(
  `${BREAKER_INSTALLATION_SLICE_NAME}/fetchBreakerInstallation`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IBreakerInstallationPage>(
        `/pages/electrical-breaker-installation/`
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
