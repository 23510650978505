import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { NotificationService } from "src/helpers/notifications";
import { postGetInTouch } from "src/redux/getInTouch/actions";
import { IGetInTouch } from "src/@types/postFormsData";
import { CONTACT_FORM_FIELDS } from "./constants";
import { Section } from "../Section";
import { RenderFields } from "../RenderFields";
import { TextInput } from "../FormField/TextInput";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import cn from "classnames";
import { selectorGetInTouchIsLoading } from "src/redux/getInTouch/selectors";
import { Loader } from "../Loader";
import { Sizes } from "src/@types/sizes";

export const GetInTouch = () => {
  const dispatch = useAppDispatch();

  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      address: "",
      message: "",
    },
  });
  const isLoading = useAppSelector(selectorGetInTouchIsLoading);

  const onSubmit: SubmitHandler<IGetInTouch> = async (data) => {
    try {
      await dispatch(postGetInTouch(data));
      NotificationService.success("success request");
      methods.reset();
    } catch (error) {
      console.error("Failed to submit form:", error);
    }
  };

  return (
    <Section
      className="wrapper relative !pt-30 sm:!pt-20"
      title="Get in touch"
      titleClassName="text-yellow-base"
    >
      <p className="absolute top-5 left-1/2 -translate-x-1/2 uppercase text-64 font-manrope font-extrabold text-gray-ligth opacity-5 lg:top-10 lg:text-9xl">
        CoNTACt
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <div className="mx-auto gl:w-4/5">
            <div className="grid gap-y-8  mb-15 sm:grid-cols-2 sm:gap-x-20 gl:justify-center gl:gap-x-50 gl:gap-y-15">
              <RenderFields fields={CONTACT_FORM_FIELDS} />
            </div>

            <TextInput
              className="mx-auto mb-10"
              type="textarea"
              id="contactComment"
              name="message"
              placeholder="Message"
            />

            <Button
              className={cn(
                "w-full py-3 mx-auto sm:max-w-77.5 gl:max-w-55 gl:mx-0 gl:ml-auto",
                {
                  "opacity-55": isLoading,
                }
              )}
              variant={ButtonVariants.PRIMARY}
              isDisabled={isLoading}
            >
              {isLoading ? <Loader size={Sizes.S} /> : "Send"}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Section>
  );
};
