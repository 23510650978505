import { createSlice } from "@reduxjs/toolkit";
import { IReviewResponce } from "src/components/ReviewSection/types";
import { REVIEWS_SLICE_NAME } from "./actions";
import { getReviewsReducer } from "./reducers";

export interface ReviewsState {
  isLoading: boolean;
  data: IReviewResponce;
}

const initialState: ReviewsState = {
  isLoading: false,
  data: null,
};

export const { reducer: reviews } = createSlice({
  name: REVIEWS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getReviewsReducer(builder);
  },
});
