import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IPostFormsData } from "src/@types/postFormsData";

export const MEMBERSHIP_SLICE_NAME = "membershipForm";

export const postMembership = createAsyncThunk(
  `${MEMBERSHIP_SLICE_NAME}/postMembership`,
  async (membershipData: IPostFormsData, { rejectWithValue }) => {
    try {
      const { data } = await instance.post<IPostFormsData>(`/join-form/`, {
        ...membershipData,
      });

      return data;
    } catch ({ response }) {
      console.log(response);

      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
