import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { INewsTipsItem } from "src/@types/newsTips";
import { getCurrentNewsTipPostAsync } from "./actions";
import { CurrentNewsTipsPostState } from "./slice";

type ActionReducerMapBuilderWithNewsTipsPostState =
  ActionReducerMapBuilder<CurrentNewsTipsPostState>;

export const getCurrentNewsTipsPostReducer = (
  builder: ActionReducerMapBuilderWithNewsTipsPostState
) => {
  builder.addCase(getCurrentNewsTipPostAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getCurrentNewsTipPostAsync.fulfilled,
    (state, action: PayloadAction<INewsTipsItem>) => {
      state.isLoading = false;
      state.post = action.payload;
    }
  );

  builder.addCase(getCurrentNewsTipPostAsync.rejected, (state) => {
    state.isLoading = false;
    state.post = null;
  });
};
