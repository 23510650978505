import { useModal } from "src/hooks/useModal";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";
import { ReactComponent as LogoIcon } from "src/assets/icons/logo-yellow-base.svg";
import { ReactComponent as PhoneIcon } from "src/assets/icons/phone-black-base.svg";
import { ReactComponent as BookIcon } from "src/assets/icons/book-black-base.svg";
import { NavMenu } from "./NavMenu";
import { Link } from "../Link";
import { BookNowModal } from "../BookNowModal";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { FC } from "react";

interface Props {
  hiddenPhone?: boolean;
}
export const Header: FC<Props> = ({ hiddenPhone = false }) => {
  const bookNow = useModal();
  const contactData = useAppSelector(selectorDetails);

  return (
    <header className="fixed hidden lg:block bg-black-base w-full z-50">
      <div className="container flex items-center justify-between px-10 py-1">
        <Link href="/">
          <div className="w-50 h-9">
            <LogoIcon />
          </div>
        </Link>

        <NavMenu />

        <div className="flex items-center gap-5 ">
          <Link
            href={
              hiddenPhone
                ? contactData?.CONTACTS[3]?.href
                : contactData?.CONTACTS[1]?.href
            }
          >
            <Button
              className="flex items-center gap-1"
              type="button"
              variant={ButtonVariants.PRIMARY_SMALL}
            >
              <PhoneIcon className="w-4 h-4" />
              Phone
            </Button>
          </Link>

          <Button
            className="flex items-center gap-1"
            type="button"
            variant={ButtonVariants.PRIMARY_SMALL}
            onClick={bookNow.openModal}
          >
            <BookIcon className="min-w-4 min-h-4" />
            <span className="whitespace-nowrap">Book now!</span>
          </Button>
        </div>
      </div>

      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
    </header>
  );
};
