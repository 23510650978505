import { createSlice } from "@reduxjs/toolkit";
import { IResponseWithPagination } from "src/@types/api";
import { ICareerItem } from "src/@types/career";
import { CAREER_SLICE_NAME } from "./actions";
import { getCareerReducer } from "./reducers";

export interface CareerState {
  isLoading: boolean;
  data: IResponseWithPagination<ICareerItem>;
}

const initialState: CareerState = {
  isLoading: false,
  data: null,
};

export const { reducer: career } = createSlice({
  name: CAREER_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getCareerReducer(builder);
  },
});
