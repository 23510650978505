import { createSlice } from "@reduxjs/toolkit";
import { IBookNowData } from "src/@types/postFormsData";
import { BOOK_NOW } from "./actions";
import { postBookNowCouponReducer, postBookNowReducer } from "./reducers";

export interface bookNowState {
  isLoading: boolean;
  data: IBookNowData;
}

const initialState: bookNowState = {
  isLoading: false,
  data: null,
};

export const { reducer: bookNow } = createSlice({
  name: BOOK_NOW,
  initialState,
  reducers: {},
  extraReducers(builder) {
    postBookNowReducer(builder);
  },
});

export const { reducer: bookNowCoupon } = createSlice({
  name: BOOK_NOW,
  initialState,
  reducers: {},
  extraReducers(builder) {
    postBookNowCouponReducer(builder);
  },
});
