import { ReactComponent as FacebookIcon } from "src/assets/icons/facebook-white-base.svg";
import { ReactComponent as InstagramIcon } from "src/assets/icons/instagram-white-base.svg";
import { ReactComponent as GoogleIcon } from "src/assets/icons/google-white-base.svg";
import { ReactComponent as EmailIcon } from "src/assets/icons/email-icon-white-base.svg";
import { ReactComponent as PhoneIcon } from "src/assets/icons/phone-white-base.svg";
import { ReactComponent as HeadsetIcon } from "src/assets/icons/support-white-base.svg";
import {
  EMAIL,
  PHONE_NUMBER,
  SECOND_PHONE_NUMBER,
} from "src/constants/contactInfo";

export const DEFAULT_FOOTER_TITLE_CLASSNAME =
  "mb-2.5 text-32 shadow-yellow-base text-shadow-sm  uppercase sm:text-32 sm:text-shadow";
export const DEFAULT_FOOTER_DIVIDE_LINE_CLASSNAME =
  "mb-6 w-full h-0.25 bg-yellow-base opacity-60";

export const LICENSE_LIST = [
  {
    id: 1,
    license: "CA# 1120994",
  },
  {
    id: 2,
    license: "CA# 1120145",
  },
];

export const WORKING_HOURS = [
  {
    id: 1,
    day: "Monday-Friday",
    hours: "8AM-5PM",
  },
  {
    id: 2,
    day: "Saturday",
    hours: "8AM-12PM",
  },
];

export const SOCIAL_LINKS = [
  {
    id: 1,
    Icon: FacebookIcon,
    href: "facebook.com",
  },
  {
    id: 2,
    Icon: InstagramIcon,
    href: "instagram.com",
  },
  {
    id: 3,
    Icon: GoogleIcon,
    href: "google.com",
  },
];

export const CONTACTS = [
  {
    id: 1,
    Icon: EmailIcon,
    label: EMAIL,
    href: `mailto:${EMAIL}`,
  },
  {
    id: 2,
    Icon: PhoneIcon,
    label: PHONE_NUMBER,
    href: `tel:${PHONE_NUMBER}`,
    // "tel:+17144651135",
  },
  {
    id: 3,
    Icon: HeadsetIcon,
    label: SECOND_PHONE_NUMBER,
    href: `tel:${SECOND_PHONE_NUMBER}`,
    // "tel:+18008010798"
  },
];
