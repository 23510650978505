import React, { lazy, ReactElement, Suspense } from "react";
import { Provider as ReduxProvider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { history } from "./services/history";
import CustomHistoryRouter from "./routes/CustomHistoryRouter";
import AppRoutes from "./routes/Router";
import { store } from "./redux/store";
import { Loader } from "./components/Loader";
import { Sizes } from "./@types/sizes";
import "@vidstack/react/player/styles/base.css";

const ToastContainer = lazy(() =>
  import("react-toastify").then((mod) => ({ default: mod.ToastContainer }))
);
const Bounce = lazy(() =>
  import("react-toastify").then((mod) => ({ default: mod.Bounce }))
);

function App(): ReactElement {
  return (
    <CustomHistoryRouter history={history}>
      <ReduxProvider store={store}>
        <div className="App flex flex-col h-screen overflow-y-auto overflow-x-hidden">
          <Suspense
            fallback={
              <div className="flex h-full w-full items-center justify-center">
                <Loader size={Sizes.XL} />
              </div>
            }
          >
            <AppRoutes />
          </Suspense>
          <ToastContainer
            position="bottom-right"
            theme="light"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            transition={Bounce}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </ReduxProvider>
    </CustomHistoryRouter>
  );
}

export default App;
