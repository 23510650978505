import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IResidentialServicesPage } from "src/@types/electricalServices";
import { getResidentialServicesPageAsync } from "./actions";
import { ResidentialServicesState } from "./slice";

type ActionReducerMapBuilderWithResidentialServicesState =
  ActionReducerMapBuilder<ResidentialServicesState>;

export const getResidentialServicesPageReducer = (
  builder: ActionReducerMapBuilderWithResidentialServicesState
) => {
  builder.addCase(getResidentialServicesPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getResidentialServicesPageAsync.fulfilled,
    (state, action: PayloadAction<IResidentialServicesPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getResidentialServicesPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
