import { ButtonVariants } from "./types";

export const BUTTON_STYLE_VARIANTS = {
  [ButtonVariants.PRIMARY]:
    "px-5 py-2 text-5 text-black-base font-manrope font-bold bg-yellow-base rounded-full hover:scale-105 transition-all duration-300",
  [ButtonVariants.PRIMARY_SMALL]:
    "px-3 py-1 text-base text-black-base font-manrope font-bold bg-yellow-base rounded-full hover:scale-105 transition-all duration-300",
  [ButtonVariants.SECONDARY]:
    "px-3 py-1 text-5 text-white font-manrope font-normal bg-black-base border border-yellow-base rounded-full hover:bg-yellow-base hover:text-black-base hover:border-black-base transition-all duration-300",
  [ButtonVariants.PRIMARY_EXTRA_PADDING]:
    "px-10 py-2 text-5 text-black-base font-manrope font-bold bg-yellow-base rounded-full hover:scale-105 transition-all duration-300",
  [ButtonVariants.WHITE]: "p-2 w-9 h-9 bg-white rounded-5",
  [ButtonVariants.YELLOW_FILLED_ROUNDED]:
    "p-2 bg-yellow-base rounded-full hover:brightness-125",
  [ButtonVariants.ACTIVE]:
    "px-3 py-1 text-xl text-black-base font-manrope bg-yellow-base border border-yellow-base rounded-full",
};
