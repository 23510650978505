import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IHeatPumpInstallationPage } from "src/@types/hvacServices";

export const HEAT_PUMP_INSTALLATION_SLICE_NAME = "heatPumpInstallation";

export const getHeatPumpInstallationPageAsync = createAsyncThunk(
  `${HEAT_PUMP_INSTALLATION_SLICE_NAME}/fetchHeatPumpInstallation`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IHeatPumpInstallationPage>(
        "/pages/heat-pump-installation/"
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
