import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IMaintenancePage } from "src/@types/hvacServices";
import { getMaintenancePageAsync } from "./actions";
import { MaintenanceState } from "./slice";

type ActionReducerMapBuilderWithMaintenanceState =
  ActionReducerMapBuilder<MaintenanceState>;

export const getMaintenancePageReducer = (
  builder: ActionReducerMapBuilderWithMaintenanceState
) => {
  builder.addCase(getMaintenancePageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getMaintenancePageAsync.fulfilled,
    (state, action: PayloadAction<IMaintenancePage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getMaintenancePageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
