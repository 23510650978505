import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { IServiceAreaResponse } from "src/@types/serviceArea";
import { getServiceAreaAsync } from "./actions";
import { IServiceAreaState } from "./slice";

type ActionReducerMapBuilderWithServiceAreaState =
  ActionReducerMapBuilder<IServiceAreaState>;

export const getServiceAreaReducer = (
  builder: ActionReducerMapBuilderWithServiceAreaState
) => {
  builder.addCase(getServiceAreaAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getServiceAreaAsync.fulfilled,
    (state, action: PayloadAction<IServiceAreaResponse>) => {
      state.isLoading = false;
      state.serviceAreaData = action.payload;
    }
  );

  builder.addCase(getServiceAreaAsync.rejected, (state) => {
    state.isLoading = false;
    state.serviceAreaData = null;
  });
};
