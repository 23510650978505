import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IEvChargerInstallationPage } from "src/@types/electricalServices";

export const EV_CHARGER_INSTALLATION_SLICE_NAME = "evChargerInstallation";

export const getEvChargerInstallationPageAsync = createAsyncThunk(
  `${EV_CHARGER_INSTALLATION_SLICE_NAME}/fetchEvChargerInstallation`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IEvChargerInstallationPage>(
        `/pages/ev-charger-installation/`
      ); //endpoint

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
