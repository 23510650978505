import React, { FC, ReactNode, RefObject } from "react";
import cn from "classnames";

interface Props {
  title: string;
  subTitle?: string;
  description?: string;
  className?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  descriptionClassName?: string;
  children: ReactNode;
  sectionRef?: RefObject<HTMLElement>;
}

export const Section: FC<Props> = ({
  title,
  subTitle,
  description,
  children,
  className,
  titleClassName,
  subTitleClassName,
  descriptionClassName,
  sectionRef,
}) => (
  <section className={cn(className)} ref={sectionRef}>
    <h3
      // lg:mb-15
      className={cn(
        "text-40 leading-9 mb-5 lg:text-64 lg:leading-16",
        titleClassName
      )}
    >
      {title}
    </h3>

    {Boolean(subTitle) && (
      <h4
        className={cn("text-34 font-bebas sm:text-center", subTitleClassName)}
      >
        {subTitle}
      </h4>
    )}
    {Boolean(description) && (
      <p
        className={cn(
          "text-base font-manrope sm:text-center",
          descriptionClassName
        )}
      >
        {description}
      </p>
    )}

    {children}
  </section>
);
