import { FC, useRef, useEffect } from "react";
import cn from "classnames";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-btn.svg";
import { BookNowForm } from "./BookNowForm";
import { ModalWindow } from "../ModalWindow";

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  discounts?: { label: string }[];
  openWithCoupon?: boolean;
}

export const BookNowModal: FC<Props> = ({
  isOpen = false,
  openWithCoupon = false,
  onClose,
  discounts,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        (!dropdownRef.current ||
          !dropdownRef.current.contains(event.target as Node))
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onClose}
      isShownCloseButton={false}
      isActiveCloseClickOutside={false}
      isActivePortal
      className={cn("top-1/2 ", {
        "!-translate-y-1/2": isOpen,
      })}
    >
      <div
        ref={modalRef}
        className="custom-scrollbar relative p-10 w-87.5 h-143.5 overflow-y-auto overflow-x-hidden rounded-10 bg-black-base sm:w-120 lg:w-155 lg:h-155 lg:px-30 lg:py-15"
      >
        <button className="absolute top-5 right-3 z-40" onClick={onClose}>
          <CloseIcon className="w-7 h-7 fill-yellow-base hover:rotate-90" />
        </button>

        {/* <div
          className={cn(
            DEFAULT_YELLOW_BLUR,
            "top-0 right-0 -translate-y-1/2 !blur-250"
          )}
        />

        <div
          className={cn(
            DEFAULT_YELLOW_BLUR,
            "bottom-0 left-0 !-translate-x-1/2 translate-y-1/2 !blur-250"
          )}
        /> */}

        <p className="mb-10 text-center capitalize text-5xl font-manrope font-bold sm:text-6xl">
          Book now
        </p>
       
          <BookNowForm
            openWithCoupon={openWithCoupon}
            discounts={discounts}
            onClose={onClose}
          />
      
      </div>
    </ModalWindow>
  );
};
