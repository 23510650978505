import { IFormField } from "src/@types/form";
import { FC } from "react";
import { TextInput } from "../FormField/TextInput";

interface Props {
  fields: IFormField[];
  fieldClassName?: string;
}

export const RenderFields: FC<Props> = ({ fields, fieldClassName }) => {
  return (
    <>
      {fields.map(({ ...field }) => (
        <TextInput
          fieldClassName={fieldClassName}
          key={field.fieldId}
          {...field}
        />
      ))}
    </>
  );
};
