import { createSlice } from "@reduxjs/toolkit";
import { ICouponsResponse } from "src/@types/coupon";
import { COUPONS_SLICE_NAME } from "./actions";
import { getCouponsReducer } from "./reducers";

export interface ICouponsState {
  isLoading: boolean;
  couponsData: ICouponsResponse[];
}
const initialState: ICouponsState = {
  isLoading: false,
  couponsData: [],
};

export const { reducer: coupons } = createSlice({
  name: COUPONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getCouponsReducer(builder);
  },
});
