import { createSlice } from "@reduxjs/toolkit";
import { IMaintenace } from "src/@types/appliance";
import { APPLIANCE } from "../installation/actions";
import { getApplianceMaintenancePageReducer } from "./reducers";

export interface ApplianceMaintenanceState {
  isLoading: boolean;
  data: IMaintenace;
}

const initialState: ApplianceMaintenanceState = {
  isLoading: false,
  data: null,
};

export const { reducer: applianceMaintenance } = createSlice({
  name: APPLIANCE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    getApplianceMaintenancePageReducer(builder);
  },
});
