import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IResponseWithPagination } from "src/@types/api";
import { ICareerItem } from "src/@types/career";

export const CAREER_SLICE_NAME = "career";

export const getCareerAsync = createAsyncThunk(
  `${CAREER_SLICE_NAME}/fetchCareer`,
  async (page: number = 1, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IResponseWithPagination<ICareerItem>>(
        `/career-posts/?page=${page}`
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
