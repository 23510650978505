import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { IEvChargerInstallationPage } from "src/@types/electricalServices";
import { getEvChargerInstallationPageAsync } from "./actions";
import { EvChargerInstallationState } from "./slice";

type ActionReducerMapBuilderWithEvChargerInstallationState =
  ActionReducerMapBuilder<EvChargerInstallationState>;

export const getEvChargerInstallationPageReducer = (
  builder: ActionReducerMapBuilderWithEvChargerInstallationState
) => {
  builder.addCase(getEvChargerInstallationPageAsync.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    getEvChargerInstallationPageAsync.fulfilled,
    (state, action: PayloadAction<IEvChargerInstallationPage>) => {
      state.isLoading = false;
      state.data = action.payload;
    }
  );

  builder.addCase(getEvChargerInstallationPageAsync.rejected, (state) => {
    state.isLoading = false;
    state.data = null;
  });
};
