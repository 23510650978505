import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { INewsTipsBookNow } from "src/@types/postFormsData";

export const NEWS_TIPS_BOOK_NOW = " news-tips-book-now";

export const postNewsTipsBookNow = createAsyncThunk(
  `${NEWS_TIPS_BOOK_NOW}/postNewsTipsBookNow`,
  async (newsTipsBookNowData: INewsTipsBookNow, { rejectWithValue }) => {
    try {
      const { data } = await instance.post<INewsTipsBookNow>(
        `/news-book-now/`,
        newsTipsBookNowData
      ); 

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.error(errorText);

      return rejectWithValue(errorText);
    }
  }
);
