import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "src/services/api-client";
import { NotificationService } from "src/helpers/notifications";
import { IApplianceRepairPage } from "src/@types/appliance";
import { APPLIANCE } from "../installation/actions";

export const getApplianceMajorRepairPageAsync = createAsyncThunk(
  `${APPLIANCE}/fetchMajorRepair`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get<IApplianceRepairPage>(
        `/pages/appliance/major-repair/`
      );

      return data;
    } catch ({ response }) {
      const errorText = response?.data?.error;

      NotificationService.updateToError(errorText);

      return rejectWithValue(errorText);
    }
  }
);
